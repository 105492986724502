.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.column:first-child {
  width: calc(70%);
}

.formCharacteristicsTitle {
  color: var(--black, #282828);
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  margin-bottom: 12px;
  font-weight: 600;
  line-height: normal;
}

.extraInput {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
  align-items: center;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
  width: 100%;
}

.formGroup span {
  color: var(--black, #282828);
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formGroup input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: 284px;
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.checkBoxField {
  display: flex;
}

.photosTitle {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
}

.standartGabarits {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

.gabaritsBtns {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.gabaritsBtns button {
  width: 184px;
  padding: 12px 20px;
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3CADFF);
  background: var(--white, #FAFAFA);
  font-family: "Montserrat";
  color: #0E86F8;
  align-items: center;
  cursor: pointer;
}

.gabaritsBtns button:hover {
  transition: 200ms;
  border: 1px solid var(--light-blue, #3CADFF);
  color: white;
  background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.gabaritsBtns .activeBtn {
  color: white;
  background-color: #0E86F8;
}

.gabaritsList {
  width: 100%;
  max-width: 300px;
}

.formVideoInputCont {
  display: flex;
  margin-top: 20px;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
}


.formVideoInputNameCont {
  display: flex;
  align-items: center;
  position: relative;
  gap: 3px;
}

.notificationBlock {
  position: absolute;
  justify-content: center;
  align-items: center;
  right: -180%;
  top: -50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 10px;
  background: var(--light-gray, #EEE);
  width: 200px !important;
  z-index: 3;
  overflow: hidden;
  color: var(--black, #282828);
  text-overflow: ellipsis;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}





.formVideoInputCont input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: calc(100% - 40px);
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.fieldError {
  color: red !important;
  font-size: 13px !important;
}

.infoImg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

@media (min-width: 1260px) and (max-width: 1350px) {
  .column:first-child {
    width: calc(60%);
  }
}

@media (max-width: 700px) {
  .wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .column:last-child {
    width: 100%;
  }

  .column:first-child {
    width: 100%;
  }

  .gabaritsBtns {
    width: 100%;
  }

  .gabaritsBtns button {
    width: 100%;
  }
}