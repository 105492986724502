.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    max-width: 320px;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

h2 {
    margin: 0;
}

.field {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 80%;
}

.field .error {
    border: 1px solid red;
}

.field span {
    font-size: 12px;
    color: red;
}

.loader {
    border: 2px solid white;
    padding: 10px;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    border-top: 2px solid #0E86F8;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.continueButton {
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
    width: 160px;
    height: 40px;
    max-width: 80%;
}

.continueButton:hover {
    opacity: 0.8;
}

.closeButton {
    position: absolute;
    width: 16px !important;
    min-width: 40px;
    max-width: 40px;
    height: 16px;
    top: 10px;
    right: 0px;
    border: none;
    background-color: transparent;
    background-image: url("../../../assets/Frame\ 872.png");
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    cursor: pointer;

}

.modalContent input {
    border-radius: 12px;
    background: #fff;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border: none;
    width: calc(100% - 20px);
}