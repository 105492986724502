@font-face {
    font-family: "Montserrat";
    src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

.container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    gap: 60px;
}

.title {
    color: var(--black, #282828);
    align-self: flex-start;
    /* H4 */
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.banners {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.addBanner {
    width: 48%;
    max-width: 590px;
    min-width: 280px;
    height: 266px;
    display: flex;
    cursor: pointer;
    gap: 20px;
    align-items: center;
    justify-content: center;
    color: gray;
    font-family: "Montserrat";
    padding: 30px 24px;
    box-sizing: border-box;
    border-radius: 28px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.addBanner:hover {
    transition: 200ms;
    color: #3CADFF;
}

@media (max-width: 700px) {

    .bodyBlock {
        width: auto !important;
    }

    .container {
        padding: 12px 40px;
    }

    .banners {
        flex-direction: column;
        align-items: center;
    }

    .addBanner {
        flex-direction: column;
        text-align: center;
        min-width: 330px;
    }
}

@media (min-width: 701px) {
    .container {
        padding: 40px 120px;
    }
}