.product {
  position: relative;
  width: 100%;
  max-width: 1200px;
  min-width: 1200px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
  max-height: 120px;
}

.product:hover {
  /* border: 1px solid gray; */
  border-radius: 3px;
  transition: 200ms;
}

.checkBoxStorage {
  /* margin-left: -65px; */
  transition: 200ms;
  /* padding: 35px; */
}

.product div:nth-child(2) {
  width: 190px;
}

.product div:nth-child(3) {
  width: 110px;
}

.product div:nth-child(4) {
  width: 220px;
}

.product div:nth-child(5) {
  width: 200px;
}

.product div:nth-child(6) {
  width: 210px;
  text-align: right;
}

.product div:nth-child(7) {
  width: 160px;
  text-align: center;
}

.product div:nth-child(8) {
  width: 120px;
  margin-right: 80px;
  text-align: right;
}

.dropdown {
  display: inline-block;
  position: relative;
  z-index: 2;
  /* ... остальные стили компонента */
}

.dropdownToggle {

  background-color: white;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Montserrat";
}

.dropdownToggle img {
  width: 17px;
  height: 17px;
}

.dropdownMenu {
  display: block;
  list-style: none;
  margin: 0;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  z-index: 3;
  position: absolute;
  padding: 0;
}

.dropdownMenu li {
  background: #fff;
  padding: 5px;
  font-size: 16px;
  font-family: "Montserrat";
  cursor: pointer;
}

.dropdownMenu li:hover {
  background: #f1f1f1;
}

.productEmptyImg {
  padding: 3px 2px;
  color: var(--gray, #ACACAC);
  text-align: center;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  width: 70px !important;
  border: 1px solid #ACACAC;
  border-radius: 18px;
  height: 67px;
  box-sizing: border-box;
  line-height: 21px;
}

.productEmptyImg span:first-child {
  font-weight: 800;
}

.productImg {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
  width: 80px !important;
}

.productsImgContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  align-items: center;
  border-radius: 10px;
}

.productActualImage {
  max-width: 80px !important;
  max-height: 67px;
}

.productsImgContainer span {
  color: var(--gray, #ACACAC);
  width: 50px;
  font-family: "Montserrat";
  font-size: 12px;
  align-self: center;
  font-style: normal;
  font-weight: 400;
}

.productName {
  overflow: hidden;
  color: var(--black, #282828);
  font-family: "Montserrat";
  /* display: flex; */
  justify-content: flex-start;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
}

.productName span:nth-child(1) img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.productPrice {
  color: gray;
  font-family: "Montserrat";
  font-size: 16px;
  display: flex;
  justify-content: center;
  text-decoration: line-through;
}

.productSalePrice {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  color: green;
  font-family: "Montserrat";
}

.productCategory {
  font-family: "Montserrat";
  font-size: 16px;
}

.productSubCategory {
  font-family: "Montserrat";
  font-size: 16px;
}

.productCorrect {
  width: 40px !important;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  background-image: url("../assets/Edit.png");
  background-position: center;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.productDelete {
  width: 16px !important;
  min-width: 40px;
  max-width: 40px;
  border: none;
  background-color: transparent;
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 36px;
  color: gray;
  transition: 200ms;
}

.productDelete:hover {
  color: red;
}

.popupBtnBack {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3CADFF);
  background: var(--white, #FAFAFA);
  font-family: 'Montserrat';
  cursor: pointer;
  font-size: 16px;
  padding: 14px 32px;
  color: #3CADFF;
}

.popupBtnBack:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.popupBtnDel {
  border-radius: 18px;
  background: var(--blue, #0E86F8);
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 16px;
  color: white;
}

.statsNotification {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 32%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 10px;
  background: var(--light-gray, #EEE);
  width: 200px !important;
  z-index: 3;
  overflow: hidden;
  color: var(--black, #282828);
  /* text-align: center; */
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.statsNotification p {
  margin: 0;
}

.editNotification {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 65%;
  left: 105%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 8px 10px;
  border-radius: 10px;
  background: var(--light-gray, #EEE);
  width: 110px;
  z-index: 1;
  overflow: hidden;
  color: var(--black, #282828);
  text-align: center;
  text-overflow: ellipsis;
  /* text-tablet */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deleteNotification {
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 65%;
  left: 107%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 8px 10px;
  border-radius: 10px;
  background: var(--light-gray, #EEE);
  width: 70px;
  z-index: 1;
  overflow: hidden;
  color: var(--black, #282828);
  text-align: center;
  text-overflow: ellipsis;
  /* text-tablet */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.popupBtnDel:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.popupClose {
  position: absolute;
  width: 16px !important;
  min-width: 40px;
  max-width: 40px;
  height: 16px;
  top: 20px;
  right: 10px;
  border: none;
  background-color: transparent;
  background-image: url("../assets/Frame\ 872.png");
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}