@media (max-width: 700px) {
  .newProductTitleContainer {
    margin-bottom: 30px;
  }

  .copyProductBtn {
    margin-top: -40px;
  }
}

.backBtn {
  display: flex;
  text-decoration: none !important;
  gap: 8px;
  color: gray;
  align-self: flex-start;
  align-items: center;
}

.backBtn img {
  width: 15px;
  height: 10px;
}

.backBtn span {
  font-family: "Montserrat";
  font-size: 16px;
  text-decoration: none;
}

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageContentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.newProductTitleContainer {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}

.newProductTitleContainer .title {
  width: auto;
}

.copyProductBtn {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #0e86f8;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: bold;
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.copyProductContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding: 20px; */
  font-size: 20px;
  font-family: "Montserrat";
}

.copyProductContent strong{
  align-self: center;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  background-color: #EEE;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #acacac;
  border-radius: 10px 10px 0px 0px;
}

.copyPositionsList {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  border-left: 1px solid #acacac;
  overflow: hidden;
  max-height: 350px;
  overflow-y: scroll;
  border-right: 1px solid #acacac;
  border-radius: 0px 0px 10px 10px;
  border-bottom: 1px solid #acacac;
  flex-direction: column;
  box-sizing: border-box;
}

.copyPositionsList span {
  cursor: pointer;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  width: 100%;
  box-sizing: border-box;
}

.copyPositionsList span:hover {
  background-color: #cbc9c9;
  border-bottom: 1px solid #0e86f8;
  border-top: 1px solid #0e86f8;
  transition: 200ms;
}

.copyPositionsListSelected {
  background-color: #cbc9c9;
  transition: 200ms;
}

.popupCopyBtns {
  display: flex;
  margin-top: 30px;
  align-items: center;
  gap: 20px;
}

.copyPopupClose {
  position: absolute;
  width: 16px !important;
  min-width: 40px;
  max-width: 40px;
  height: 16px;
  z-index: 10;
  top: 20px;
  right: 10px;
  border: none;
  background-color: transparent;
  background-image: url("../../assets/Vector.svg");
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.title {
  color: var(--black, #282828);
  width: 100%;
  font-family: "Montserrat";
  font-size: 22px;
  flex-shrink: 0;
  font-style: normal;
  text-align: left;
  font-weight: 600;
  align-self: flex-start;

  text-transform: uppercase;
}

.mainBtns {
  margin-top: 80px;
  margin-bottom: 100px;
  gap: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.mainBtns button {
  cursor: pointer;
  flex-grow: 1;
}

.mainBtns button:hover {
  transition: 200ms;
  color: white !important;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%) !important;
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.mainBtns button:nth-child(1) {
  border-radius: 18px;
  border: 1px solid var(--gray, #acacac);
  padding: 14px 32px;
  font-size: 16px;
  width: auto;
  font-family: "Montserrat";
  color: #acacac;
  background: var(--white, #fafafa);
}

.mainBtns button:nth-child(2) {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  padding: 14px 32px;
  color: #3cadff;
  font-size: 16px;
  font-family: "Montserrat";
}

.mainBtns button:nth-child(3) {
  border-radius: 18px;
  background: var(--blue, #0e86f8);
  padding: 14px 32px;
  color: white;
  border: none;
  font-size: 16px;
  font-family: "Montserrat";
}

/* LEGACY */

.mainBtns {
  gap: 40px;
  display: flex;
  align-items: center;
}

.mainBtns button {
  cursor: pointer;
}

.mainBtns button:hover {
  transition: 200ms;
  color: white !important;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%) !important;
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.mainBtns button:nth-child(1) {
  border-radius: 18px;
  border: 1px solid var(--gray, #acacac);
  padding: 14px 32px;
  font-size: 16px;
  font-family: "Montserrat";
  color: #acacac;
  background: var(--white, #fafafa);
}

.mainBtns button:nth-child(2) {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  padding: 14px 32px;
  color: #3cadff;
  font-size: 16px;
  font-family: "Montserrat";
}

.mainBtns button:nth-child(3) {
  border-radius: 18px;
  background: var(--blue, #0e86f8);
  padding: 14px 32px;
  color: white;
  border: none;
  font-size: 16px;
  font-family: "Montserrat";
}

.popupBtnBack {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  font-family: "Montserrat";
  cursor: pointer;
  font-size: 16px;
  padding: 14px 32px;
  color: #3cadff;
}

.popupBtnBack:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupBtnDel {
  border-radius: 18px;
  background: var(--blue, #0e86f8);
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 16px;
  color: white;
}

.popupBtnDel:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupClose {
  position: absolute;
  width: 16px !important;
  min-width: 40px;
  max-width: 40px;
  height: 16px;
  top: 20px;
  right: 10px;
  border: none;
  background-color: transparent;
  background-image: url("../../assets/Frame\ 872.png");
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (max-width: 700px) {
  .title {
    margin: 30px 0px;
  }

  .backBtn {
    margin-top: 50px;
  }
}