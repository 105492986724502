.wrapper {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 12px;
  border: 1px solid #acacac;
  display: flex;
  justify-content: center;
}

.wrapper:hover {
  border-radius: 16px;
  border: 1px solid #3cadff;
}

.mainImg {
  width: 280px;
  flex-shrink: 0;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 12px;
}

.emptyItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper button {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: none;
  background-color: #fff;
  border-radius: 2px;
  background-image: url("../../../../../../assets/Frame\ 872-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
}

.wrapper button:hover {
  background-image: url("../../../../../../assets/Property\ 1=cancel-hover.png");
}