.error-message {
    display: flex;
    border: 1px solid var(--attention, #E62121);
    color: var(--attention, #E62121);
    gap: 10px;
    padding: 12px 20px;
    border-radius: 20px;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 250px;
}