@media (max-width: 1100px) {
  body {
    width: 100vw !important;
  }
}

.orders-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.orders-page h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.megaContainer {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
}
