.product {
    border-radius: 20px;
    border: 1px solid var(--light-gray, #EEE);
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    display: flex;
    padding: 20px;
    width: 310px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.nameImg {
    display: flex;
    align-items: center;
    gap: 12px;
}

.productName {
    overflow: hidden;
    color: var(--blue, #0E86F8);
    text-overflow: ellipsis;
    /* ST2-tablet */
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-self: baseline;
}

.productStatus {
    display: flex;
    gap: 20px;
    align-items: center;
}

.productStatus span:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}

.dropdown {
    display: inline-block;
    position: relative;
    z-index: 2;
    /* ... остальные стили компонента */
}

.dropdownToggle {
    padding: 8px 20px;
    border-radius: 12px;
    border: 1px solid var(--not-main-action, #747474);
    background: var(--white, #FAFAFA);
    color: #747474;
    display: flex;
    font-weight: 600;
    align-items: center;
    gap: 8px;
    font-family: "Montserrat";
}

.dropdownToggle img {
    width: 17px;
    height: 17px;
}

.dropdownMenu {
    display: block;
    list-style: none;
    border: 1px solid var(--not-main-action, #747474);
    margin: 0;
    top: 0;
    overflow: hidden;
    left: 0;
    background-color: #FAFAFA;
    width: 100%;
    border-radius: 12px;
    padding: 8px;
    z-index: 3;
    position: absolute;
    padding: 0;
}

.dropdownMenu li {
    background: #FAFAFA;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #747474;
    font-family: "Montserrat";
    cursor: pointer;
}

.dropdownMenu li:hover {
    background: #f1f1f1;
}

.productEmptyImg {
    padding: 3px 2px;
    color: var(--gray, #ACACAC);
    text-align: center;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    width: 94px !important;
    border: 1px solid #ACACAC;
    border-radius: 18px;
    height: 80px;
    box-sizing: border-box;
    line-height: 21px;
}

.productEmptyImg span:first-child {
    font-weight: 800;
}

.productImg {
    display: flex;
    justify-content: flex-start;
    border-radius: 8px;

    width: 94px !important;
}

.productsImgContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
    align-items: center;
    border-radius: 10px;
    min-width: 100px;
}

.productActualImage {
    max-width: 94px !important;
    border-radius: 8px;
    max-height: 80px;
}

.productsImgContainer span {
    color: var(--gray, #ACACAC);
    width: 50px;
    font-family: "Montserrat";
    font-size: 12px;
    align-self: center;
    font-style: normal;
    font-weight: 400;
}

.productCat {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-items: flex-start;
}

.productCat span:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}

.productPriceContainer {
    display: flex;
    width: 100%;
    gap: 4px;
    flex-direction: column;
}

.productPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productPrice span:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}

.productPrice span:nth-child(2) {
    color: var(--not-main-action, #747474);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: line-through;
}

.productSalePrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productSalePrice span:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
}


.productSalePrice span:nth-child(2) {
    color: green;
}


.productCorrect {
    border: none;
    display: flex;
    width: 244px;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 18px;
    align-self: center;
    font-family: "Montserrat";
background: var(--blue, #0E86F8);
color: white;
}

.productDelete {
    border-radius: 18px;
border: 1px solid var(--light-blue, #3CADFF);
background: var(--white, #FAFAFA);
display: flex;
width: 244px;
padding: 12px 32px;
justify-content: center;
align-items: center;
color: #3CADFF;
align-self: center;
gap: 10px;
}