.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 50%;
    max-width: 1000px;
    max-height: 80vh;
    overflow-y: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.productsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.productList {
    padding-left: 56px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-self: center;
}

.productItem {
    position: relative;
    width: 100px;
    height: 120px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.productItem img {
    width: 40px;
    height: 50px;
    max-width: 80px;
    max-height: 80px;
}

.productItem.selected {
    border-color: green;
}

.checkmark {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 16px;
    color: green;
}

.continueButton {

    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
    max-width: 320px;

}

.continueButton:hover {
    opacity: 0.8;
}

.closeButton {
    position: absolute;
    width: 16px !important;
    min-width: 40px;
    max-width: 40px;
    height: 16px;
    top: 10px;
    right: 0px;
    border: none;
    background-color: transparent;
    background-image: url("../../../assets/Frame\ 872.png");
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    cursor: pointer;

}