.wrapper {
  width: 100%;
}

.formCharacteristics {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
  width: 80%;
}

.formCharacteristics input {
  flex-grow: 1;
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: 284px;
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.formCharacteristics button {
  border: none;
  width: 50px;
  height: 40px;
  background-image: url("../../../../../assets/icon\ addPlus.png");
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 50px 40px;
  background-position: left;
  background-color: transparent;
}

.formCharacteristicsList {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  align-items: flex-start;
}

.character {
  display: flex;
  font-family: "Montserrat";
  align-items: center;
  justify-content: space-around;
  width: 80%;
}

.character span:nth-child(1) {
  width: 80%;
  padding: 8px;
}

.character span:nth-child(2) {
  width: 80%;
  padding: 8px;
  margin-right: 10px;
}

.character button {
  width: 12px;
  cursor: pointer;
  height: 12px;
  background-image: url("../../../../../assets/Frame\ 872-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  border: none;
  background-color: transparent;
}

.characterDub {
  display: flex;
  font-family: "Montserrat";
  align-items: center;
  justify-content: space-around;
  width: 80%;
}

.characterDub span:nth-child(1) {
  width: 80%;
  padding: 8px;
  background-color: #acacac33;
}

.characterDub span:nth-child(2) {
  width: 80%;
  padding: 8px;
  margin-right: 10px;
  background-color: #acacac33;
}

.characterDub button {
  width: 12px;
  height: 12px;
  background-color: transparent;
  cursor: pointer;
  background-image: url("../../../../../assets/Frame\ 872-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  border: none;
}

@media (max-width: 700px) {
  .formCharacteristics {
    width: 100%;
  }

  .character,
  .characterDub {
    width: 100%;
  }

  .formCharacteristics input {
    width: auto;
  }
}