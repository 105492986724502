.periodSelector {
    display: flex;
    z-index: 4;
    gap: 10px;
    margin: 20px 0px 20px 0px;
    align-self: flex-start;
    flex-wrap: wrap;
}

.periodButton {
    padding: 10px 20px;
    background-color: #fff;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    border: 1px solid #007bff;
    display: flex;
    box-sizing: border-box;
    font-size: 15px;
    width: 200px;
    position: relative;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s;
}

/* .periodButton::before {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 50%;
    background-color: #007bff;
    border: 1px solid #007bff;
} */



.active {
    border: 1px solid #007bff;
    transition: 200ms;
    background-color: #007bff;
    color: #fff;
    box-sizing: border-box;
}

.periodButton.active .sale10,
.periodButton.active .sale20,
.periodButton.active .sale30 {
    color: #fff !important;
}





.active::before {
    content: "";
    transition: 200ms;
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 50%;
    position: relative;
    outline: 1px solid #ffffff;
    outline-offset: 2px;
    background-color: #ffffff;
}


.sale10 {
    color: black;
    padding: 2px;
    position: absolute;
    right: 6px;
    border-radius: 3px;
    font-weight: 600;
}

.sale20 {
    color: black;
    position: absolute;
    right: 6px;
    padding: 3px;
    border-radius: 2px;
    font-weight: 600;
}


.sale30 {
    color: black;
    padding: 3px;
    position: absolute;
    right: 6px;
    border-radius: 2px;
    font-weight: 600;
}

@media (max-width: 700px) {
    .periodSelector {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    .periodButton {
        width: 356px;
    }
}