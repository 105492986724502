.notFoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.notFoundHeader {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.notFoundText {
    font-size: 1rem;
    color: #888;
}

.logo {
    width: 100px;
    height: 100px;
    animation: spin 10s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}