.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
