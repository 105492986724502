.buttonSecondary {
  width: 100%;
  margin: 0 !important;
  color: #3cadff;
  border: 1px solid #3cadff;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border-radius: 18px;
  padding: 14px 32px 14px 32px;
  background: none;
  font-size: 14px;
  position: relative;
  z-index: 2;
  margin-right: 20px;
  margin-left: 20px;
}

.buttonSecondary:hover {
  background: linear-gradient(321.48deg, #3cadff 45.48%, #ffffff 132.16%);
  box-shadow: 0px 0px 0px 0px #1111222e, 0px 3px 6px 0px #1111222e,
    0px 11px 11px 0px #11112226, 0px 24px 14px 0px #11112217,
    0px 43px 17px 0px #11112208, 0px 67px 19px 0px #11112200;
  color: white;
  transition: 600ms;
}

.buttonPrimary {
  width: 100%;
  margin: 0 !important;
  color: #fafafa;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border-radius: 18px;
  border: none;
  padding: 14px 32px 14px 32px;
  background: #0e86f8;
  font-size: 14px;
  position: relative;
  z-index: 2;
  margin-right: 20px;
  margin-left: 20px;
}

.buttonPrimary:hover {
  background: linear-gradient(321.48deg, #3cadff 45.48%, #ffffff 132.16%);
  box-shadow: 0px 0px 0px 0px #1111222e, 0px 3px 6px 0px #1111222e,
    0px 11px 11px 0px #11112226, 0px 24px 14px 0px #11112217,
    0px 43px 17px 0px #11112208, 0px 67px 19px 0px #11112200;
  color: white;
  transition: 600ms;
}

.wrapper {
  width: 100%;
}
