.createGroupButton {
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    display: flex;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
    max-width: 320px;
    transition: 200ms;
}

.createGroupButton:hover {
    opacity: 0.8;
}