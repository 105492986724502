@font-face {
  font-family: "Montserrat";
  src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
  .bodyRuleDisappear {
    width: auto !important;
  }

  .container {
    /* padding: 12px 40px; */
    gap: 40px;
  }

  .title {
    width: 284px;
    margin-top: 10px;
  }

  .menuOptions {
    flex-direction: column;
    font-size: 14px;
    width: 284px;
    margin-top: 100px;
  }

  .menuOptions span {
    width: 240px;
    border-radius: 20px;
    text-align: center;
  }

  .profileContainer {
    flex-direction: column;
  }

  .profileBlock {
    padding: 30px 20px;
    width: 284px;
  }

  .profileBlockGroup span {
    font-size: 14px;
  }

  .paymentContainer {
    width: 326px;
    padding: 20px;
    font-size: 14px;
  }

  .PayTitle {
    font-size: 18px;
  }

  .payCheckText {
    display: none !important;
  }

  .tarifContainer {
    flex-direction: column;
  }

  .tarif {
    padding: 30px 20px;
    width: 326px;
    font-size: 14px;
    height: 520px;
  }

  .saleText {
    font-size: 16px;
    margin-bottom: 20px;
  }



  .paymentForbidden {
    font-size: 14px;
  }

  .cancel {
    flex-direction: column;
    display: flex;
  }

  .cancelBtn {
    width: 150px;
    margin-top: 10px;
  }

  .profileDelContent {
    width: 326px;
  }
}

@media (min-width: 701px) {
  .container {
    padding: 40px 120px;
    gap: 40px;
  }

  .title {
    align-self: flex-start;
  }

  .menuOptions {
    align-self: flex-end;
  }

  .menuOptions span:hover:first-child {
    border-radius: 20px 0 0 20px;
  }

  .menuOptions span:hover:last-child {
    border-radius: 0 20px 20px 0;
  }

  .profileBlock {
    padding: 40px 56px;
    height: 650px;
    width: 384px;
    box-sizing: border-box;
  }

  .profileBlockTitle {
    margin-left: 50px;
    margin-bottom: 32px;
  }

  .paymentContainer {
    max-width: 1200px;
    min-width: 700px;
    padding: 40px;
    width: calc(100vw - 120px);
  }

  .PayTitle {
    font-size: 22px;
  }

  .tarif {
    height: 545px;
    padding: 40px 16px;
    width: 558px;
    font-size: 18px;
  }

  .saleText {
    font-size: 22px;
  }

  .paymentForbidden {
    font-size: 22px;
  }

  .cancelBtn {
    margin-left: 10px;
  }
}

@media (max-width: 1600px) {
  .profileContainer {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.megaContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.container {
  max-width: 1200px;
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuOptions {
  display: flex;
  border-radius: 20px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
  align-items: center;
}

.menuOptions span {
  padding: 12px 20px;
  cursor: pointer;
  font-family: "Montserrat";
}

.menuOptions span:hover {
  background: #0e86f8;
  color: white;
  transition: 200ms;
}

.title {
  color: var(--black, #282828);
  /* H4 */
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.profileContainer {
  display: flex;
  gap: 20px;
}

.profileBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
}

.profileBlockTitle {
  color: var(--black, #282828);
  /* ST2 */
  font-family: "Montserrat";
  font-size: 18px;
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profileBlockGroup {
  position: relative;
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.profileBlockGroup span {
  color: var(--black, #282828);
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profileBlockGroup input {
  display: flex;
  width: 284px;
  padding: 12px 20px;
  box-sizing: border-box;
  justify-content: center;
  font-size: 16px;
  border: none;
  font-family: "Montserrat";
  align-items: center;
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
  gap: 10px;
}

.profileBtn {
  border-radius: 18px;
  border: 1px solid var(--gray, #acacac);
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  color: #acacac;
  background: var(--white, #fafafa);
  display: flex;
  width: 284px;
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.profileBtnActive {
  border: 1px solid var(--blue, #0e86f8) !important;
  background: var(--white, #fafafa) !important;
  color: #0e86f8 !important;
}

.profileBtnActive:hover {
  color: #fafafa !important;
  background: #0e86f8 !important;
  transition: 200ms;
}

.profileHelp {
  align-self: center;
  width: 284px;
}

.profileHelp a {
  color: #282828;
}

.toolTipText {
  color: var(--black, #282828);
  font-size: 16px;
  font-style: normal;
  width: 284px;
  line-height: normal;
}

.toolTipText span {
  font-weight: 600;
}

.profileCheckBox {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  margin-bottom: 32px;
  font-family: "Montserrat";
  gap: 8px;
}

.check {
  display: flex;
  align-items: center;
}

.authorizationFormRemember {
  -webkit-appearance: none;
  apperance: none;
  background-color: rgb(255, 255, 255);
  width: 52px;
  height: 27px;
  border: 1px solid #acacac;
  border-radius: 20px;
  position: relative;
  transition-property: background-color;
  transition-duration: 350ms;
}

.authorizationFormRemember::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background: #acacac;
  height: 23px;
  width: 24px;
  top: 1px;
  transition-property: left;
  left: 1px;
  transition-duration: 600ms;
  cursor: pointer;
}

.authorizationFormRemember:checked {
  background-color: #ffffff;
  border: 1px solid #0e86f8;
  transition: 600ms;
}

.authorizationFormRemember:checked::before {
  left: 25px;
  background-color: #0e86f8;
  transition: 350ms;
}

.goBtn {
  text-decoration: none;
  color: #0e86f8;
}

.emailInfoNotification {
  position: absolute;
  top: 35%;
  left: 72%;
  transform: translateX(-50%);
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  width: 250px;
  z-index: 1;
}

.paymentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
  border-radius: 20px;
  box-sizing: border-box;
  position: relative;
}


.paymentForbiddenContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}



.paymentForbidden {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: center;
  flex-shrink: 0;
  font-weight: 600;
  color: rgb(233, 67, 67);
}

.tinText {
  display: flex;
  gap: 3px;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}

.paymentInfo {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.paymentInfoFields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 100px;
  padding: 60px 0 0 0;
  width: 100%;
}

.paymentActionsWrapper {
  width: 100%;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.paymentSubmitButton {
  background-color: #0e86f8;
  border-radius: 18px;
  color: #fff;
  height: 49px;
  width: 269px;
  border: none;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.paymentSubmitMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0 40px 40px 40px;
  border-radius: 18px;
  border: 1px solid #007d14;

  height: 49px;
  width: 100%;

  color: #007d14;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
}

.fieldsColumnWrapper {
  min-height: 50px;
}

.profileBlockGroupWrapper {
  width: 284px;
}

.fieldsColumnTitle {
  margin-top: 0;
  margin-bottom: 24px;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
}

.fieldsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.PayTitle {
  color: var(--not-main-action, #282828);

  /* H4 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.paymentInfo span {
  display: flex;
  align-items: center;
}

.titlePay {
  color: var(--black, #282828);

  /* H4 */
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.popupBtnBack {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  font-family: "Montserrat";
  cursor: pointer;
  font-size: 16px;
  z-index: 3;
  position: relative;
  padding: 14px 32px;
  color: #3cadff;
}

.popupBtnBack:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupBtnDel {
  border-radius: 18px;
  background: var(--blue, #0e86f8);
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 16px;
  color: white;
}

.popupBtnDel:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupClose {
  position: absolute;
  width: 16px !important;
  min-width: 40px;
  max-width: 40px;
  height: 16px;
  top: 20px;
  right: 10px;
  border: none;
  background-color: transparent;
  background-image: url("../../../assets/Frame\ 872.png");
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.tarifsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.tarifContainer {
  display: flex;
  gap: 20px;
}

.tarif {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  border-radius: 30px;
  border: 1px solid var(--light-blue, #3cadff);
  background-color: var(--white, #fafafa);
  background-position: right top;
  background-repeat: no-repeat;
  position: relative;
}

.tarif:nth-child(2) {
  background-image: url("../../../assets/55.png");
}

.tarif::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fafafa;
  opacity: 0.6;
}

.tarifTitle {
  color: var(--black, #282828);

  /* H4 */
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  position: relative;
  z-index: 3;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.saleText {
  color: var(--light-blue, #3cadff);
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  z-index: 3;
  line-height: normal;
}

.activeTarif {
  background: #0e86f8 !important;
  color: #fafafa !important;
}

.advantage {
  margin-top: 15px;
  position: relative;
  z-index: 3;
  display: flex;
  gap: 6px;
}

.advantage img {
  width: 22px;
  height: 20px;
}

.sale {
  color: var(--gray, #acacac);
  font-size: 16px;
  font-style: normal;
  align-self: flex-start;
  margin-top: 40px;
  font-weight: 400;
  line-height: normal;
}

.cancel {
  align-self: flex-start;
  margin-top: 40px;
}

.cancel a {
  color: #282828;
}

.cancelBtn {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  font-family: "Montserrat";
  cursor: pointer;
  font-size: 14px;
  z-index: 3;
  position: relative;
  padding: 10px 28px;
  color: #3cadff;
}

.cancelBtn:hover {
  transition: 200ms;
  color: white;
  border: 1px solid var(--light-red, red);
  background: linear-gradient(321deg, rgb(233, 67, 67) 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.profileDelContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profileDelContent {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 30px 20px;
}

.profileDelContainer h1 {
  margin-bottom: 10px;
}

.profileDelContainer .spanInfo {
  max-width: 900px;
}

.profileDelContainer button {
  max-width: 300px;
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin: 0px auto;
}

.button {
  width: 284px;
}

.modalHeader {
  margin-bottom: 0;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
}

.modalCard {
  position: relative;
  border-radius: 20px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  background-size: 200%;
  background-position: center;
  background-repeat: no-repeat;
  width: 380px;
  height: 300px;
}

.modalCardCloseIcon {
  right: 12px;
  top: 12px;
  z-index: 2000;
  position: absolute;
}

.modalCardCloseIcon:hover {
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.list div {
  margin: 10px;
}

.list input[type="checkbox"] {
  display: none;
}

.list label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  align-items: center;
}

.list label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  width: 12px;
  height: 12px;
  border: 1px solid #333;
  border-radius: 4px;
}

.list label:after {
  content: "✔";
  font-size: 10px;
  color: #3cadff;
  position: absolute;
  left: 3.5px;
  top: 0.5px;
  opacity: 0;
  transition: opacity 0.2s;
}

input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.fieldError {
  color: red !important;
  font-size: 13px !important;
}