* {
    font-family: "Montserrat";
}

@media (max-width: 700px) {
    .bodyBlock {
        width: auto !important;
    }

    .loginPagePreview {
        display: none;
    }

    .regContainer {
        box-sizing: border-box;
    }

    .choseTypeContainer {
        flex-direction: column;
        gap: 10px;
    }

    .choseTypeContainer div:nth-child(1) {
        font-family: "Montserrat";
        font-weight: 600;
        flex-shrink: 0;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
        width: 195px;
        color: gray;
        border: 1px solid gray;
        padding: 14px 32px;
        border-radius: 18px;
    }

    .choseTypeContainer div:nth-child(2) {
        font-family: "Montserrat";
        font-weight: 600;
        cursor: pointer;
        width: 195px;
        flex-shrink: 0;
        text-align: center;
        font-size: 16px;
        color: gray;
        border: 1px solid gray;
        padding: 14px 32px;
        border-radius: 18px;
    }

    .regFormRow {
        flex-direction: column;
    }

    .pasMessage {
        align-self: center;
    }

    .checkboxLabel {
        font-size: 14px;
        width: 244px;
        align-self: center;
    }
}

@media (min-width: 701px) {
    .loginPagePreview {
        display: flex;
    }

    .regForm {
        width: 510px;
    }

    .choseTypeContainer div:nth-child(1) {
        font-family: "Montserrat";
        font-weight: 600;
        flex-shrink: 0;
        font-size: 16px;
        cursor: pointer;
        color: gray;
        border: 1px solid gray;
        padding: 14px 32px;
        border-radius: 18px 0px 0px 18px;
    }

    .choseTypeContainer div:nth-child(2) {
        font-family: "Montserrat";
        font-weight: 600;
        cursor: pointer;
        flex-shrink: 0;
        font-size: 16px;
        color: gray;
        border: 1px solid gray;
        padding: 14px 32px;
        border-radius: 0px 18px 18px 0px;
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loginPagePreview {
    flex-direction: column;
    background: var(--light-blue, #3CADFF);
    justify-content: space-between;
    align-items: flex-start;
    width: 86%;
    padding: 160px 20px 80px 120px;
    box-sizing: border-box;
    align-items: center;
}

.regContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* height: 100vh; */
    align-items: center;
}

.regTitle {
    font-family: "Montserrat";
}

.regForm {
    display: flex;
    align-self: center;
    gap: 20px;
    flex-direction: column;
}

.regFormRow {
    display: flex;
    align-items: center;
    gap: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    gap: 8px;
    align-items: flex-start;
    position: relative;
}

.passwordToggleButton {
    position: absolute;
    top: 72%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #888;
}

.formInput {
    display: flex;
    width: 244px;
    height: 48px;
    padding: 14px 20px;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    border: none;
    border-radius: 12px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    backdrop-filter: blur(2px);
    font-family: "Montserrat";
}

.formInputWrong {
    border: 2px solid #e74c3c;
}

.pasMessage {
    width: 200px;
    color: red;
    font-family: "Montserrat";
    font-size: 12px;
}

.errorText {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 5px;
}

.choseTypeContainer {
    display: flex;
    align-self: center;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 10px;
    /* margin-left: 40px; */
}

.activeType {
    background-color: #007bff;
    color: white !important;
    flex-shrink: 0 !important;
    border: 1px solid transparent !important;
    transition: 200ms;
}

.regBtn {
    display: flex;
    width: 244px;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    font-size: 16px;
    color: #FFF;
    margin: auto;
    cursor: pointer;
}

.regBtn:hover {
    transition: 400ms;
    background: linear-gradient(358deg, #3CADFF 0%, #FFF 100%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}


.fileInput {
    display: flex;
    gap: 12px;
    font-family: "Montserrat";
    align-items: center;
    width: 250px;
}


.fileInput img {
    flex-shrink: 0;
    width: 16px;
    height: 18px;
}

.nameFields {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkboxLabel {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;
}

.checkboxLabel span {
    flex-wrap: nowrap;
}

.checkboxLabel a {
    flex-wrap: nowrap;
}