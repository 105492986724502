.item {
    width: 284px;
    border-radius: 20px;
    border: 1px solid var(--light-gray, #EEE);
    background: var(--white, #FAFAFA);
    padding: 20px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.option {
    display: flex;
}

.option span:nth-child(1) {
    color: var(--black, #282828);

    /* mob-button */
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.option span:nth-child(2) {
    font-size: 14px;
}

.option:nth-child(1) {
    flex-direction: column;
}

.option:nth-child(2) {
    gap: 4px;
}

.option:nth-child(3) {
    gap: 4px;
}

.option:nth-child(4) {
    flex-direction: column;
}

.option:nth-child(5) {
    flex-direction: column;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--blue, #0E86F8);

    /* mob-button */
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.item button {
    display: flex;
width: 100%;
height: 50px;
padding: 14px 32px;
color: var(--light-blue, #3CADFF);
text-align: center;

/* mob-button */
font-family: "Montserrat";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
justify-content: center;
border-radius: 18px;
border: 1px solid var(--light-blue, #3CADFF);
background: var(--white, #FAFAFA);
align-items: center;
gap: 10px;
}