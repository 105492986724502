@media (max-width: 700px) {
    .container {
        width: 390px !important;
        box-sizing: border-box;
    }

    .buttons {
        flex-direction: column;
        align-self: center;
    }

    .buttons button {
        width: 284px;
    }

    .table {
        border-radius: 0px !important;
    }

    .table th {
        font-size: 12px;
        width: 120px;
    }

    .table th:nth-child(2) {
        margin-left: -20px !important;
    }

    .table th:nth-child(3) {
        margin-left: -35px !important;
    }


    .userList .item {
        gap: 10px !important;
        font-size: 12px !important;
    }

    .userList .item td {
        min-width: 10px !important;
        overflow: hidden;
        max-width: calc(100% / 3);
        width: 100px;
        text-align: right !important;
    }

    .tooltip {
        font-size: 11px;
    }

    .toolTipBtn {
        width: 15px !important;
        height: 15px !important;
        font-size: 10px !important;
    }
}

@media (min-width: 701px) {
    .buttons button {
        width: 194px;
    }

    .table {
        min-width: 1100px;
    }

    .table thead tr th{
        width: 340px;
    }

    .table tbody tr td:last-child {
        margin-left: 200px !important;
    }

    .lastVisitTh {
        margin-left: 100px;
    }

    .table tbody tr td:nth-child(2) {
        margin-left: 200px !important;
    }

}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.table {
    border-radius: 12px;
    height: 100px;
    padding: 10px;
    overflow-y: scroll;
    overflow: hidden;
    border-collapse: collapse;
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.table thead {
    text-align: center;
    font-family: "Montserrat";
    margin: 0;
    color: var(--black, #282828);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    flex-shrink: 0;
    background: var(--white, #FAFAFA);
}

.table thead tr {
    display: flex;
}

.table thead th {
    display: flex;
    align-items: center;
}

.buttons {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.buttons button {
    cursor: pointer;
    border-radius: 18px;
    padding: 14px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
}

.buttons button:nth-child(1) {
    background: var(--blue, #0E86F8);
    border: none;
    font-family: "Montserrat";
    color: white;

}

.buttons button:nth-child(2) {
    color: #3CADFF;
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);
    width: auto;
}

.buttons button:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);

}

.userList {
    max-height: 190px;
    overflow-y: auto;
    padding: 20px;
}

.userList::-webkit-scrollbar {
    width: 16px;
}

.userList::-webkit-scrollbar-thumb {
    border-radius: 20px 20px 20px 20px;
    background: var(--gray, #acacac);
}

.userList .item {
    display: flex;
    position: relative;
    margin: 20px;
    justify-content: space-between;
}

.userList .item td {
    min-width: 150px;
    position: relative;
    text-align: right;
}

.itemUsername {
    display: flex;
    align-items: center;
    max-width: 150px;
    flex-wrap: nowrap;
}

.itemUsername a {
    text-decoration: none;
    color: #000;
}

.sortBtn {
    width: 30px;
    height: 30px;
    background-image: url("../../../assets/sort.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.table th:nth-child(2) .sortBtn {
    right: 0px;
}


.tooltip {
    background-color: transparent;
    color: black;
    border-radius: 4px;
    font-weight: 600;
    width: 100%;
    display: flex;
    margin-left: 50px;
}

.tooltip ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.toolTipBtn {
    border: 1px solid #0E86F8;
    background-color: #0E86F8;
    font-size: 16px;
    cursor: pointer;
    color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-left: 4px;
}