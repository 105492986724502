@media (max-width: 700px) {
    .bodyBlock {
        width: auto;
    }

    .loginPageContainer {
        width: 70% !important;
        align-self: flex-start !important;
        justify-self: flex-start !important;
    }

    .loginPagePreview {
        display: none !important;
    }
}

@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    src: url("../../styles/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    src: url("../../styles/fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

body {
    margin: 0;
}

.container {
    display: flex;
    justify-content: center;
}

.extraTitle {
    font-family: "Montserrat";
    color: black;
}

.loginPagePreview {
    flex-direction: column;
    background: var(--light-blue, #3CADFF);
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    display: flex;
    padding: 160px 20px 80px 120px;
    box-sizing: border-box;
    align-items: center;
}

.loginPagePreviewHeader {
    gap: 20px;
    max-width: 400px;
}

.loginPagePreviewHeader h4 {
    font-size: 22px;
    font-family: "Montserrat";
    text-transform: uppercase;
}

.loginPagePreviewHeader p {
    color: var(--black, #282828);

    /* text */
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
}

.loginPagePreviewFooter {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: "Montserrat";
}

.loginPagePreviewFooter a:hover {
    text-decoration: underline;
}

.loginPagePreviewFooter img {
    width: 212px;
    height: 43px;
}

.loginPagePreviewFooter a {
    color: #282828;
    text-decoration: none;
}

.loginPageContainer {
    display: flex;
    align-items: center;
    width: 55%;
    justify-content: center;
    background-color: white;
    height: 100vh;
}

.loginPage {
    max-width: 400px;
    width: 327px;
    min-height: 348px;
    padding: 20px;
    gap: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
}

.loginPage h4 {
    margin: 0px;
    color: var(--black, #282828);
    font-family: "Montserrat";
    font-size: 22px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
}

.formGroup {
    margin-bottom: 20px;
    position: relative;
}

.passwordToggleButton {
    position: absolute;
    top: 31%;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #888;
}

.formLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formInput {
    display: flex;
    width: 100%;
    height: 48px;
    padding: 14px 20px;
    box-sizing: border-box;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background: #FFF;
    border: none;
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    backdrop-filter: blur(2px);
}

.loginButton {
    display: flex;
    width: 244px;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    font-size: 16px;
    color: #FFF;
    cursor: pointer;
}

.loginButton:hover {
    border-radius: 18px;
    transition: 400ms;
    background: linear-gradient(358deg, #3CADFF 0%, #FFF 100%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.registerLink {
    margin-top: 10px;
    font-size: 14px;
    font-family: "Montserrat";
}

.registerLink a {
    color: #007bff;
    text-decoration: none;
}

.registerLink a:hover {
    text-decoration: underline;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.fastLinks {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    max-width: 270px;
}

.fastLinks p {
    margin: 4px;
}