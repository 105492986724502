@font-face {
  font-family: "Montserrat";
  src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
  .dataContainer {
    flex-direction: column;
  }

  .textContainer textarea {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
  }

  .userList {
    width: 320px;
  }
}

@media (min-width: 701px) {
  .textContainer textarea {
    width: 386px;
    min-width: 386px;
    max-width: 386px;
  }

  .userList {
    width: 284px;
  }
}

.item {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 12px 20px;
  border-top: 1px solid #3CADFF;
  box-sizing: border-box;
  transition: color;
  justify-content: space-between;
  width: 100%;
}

.item:hover {
  color: white;
  cursor: pointer;
  background-color: #3CADFF;
}

.item:hover .itemBtn {
  background-color: #3CADFF;
  background-image: url("../../../assets/plus.svg");
}

.item span {
  display: inline;
  animation: "appear";
  width: 100%;
  animation-duration: 700ms;
}

.item .dataContainer {
  display: none;
  font-size: 14px;
  width: 100%;
  animation: "disappear";
  animation-duration: 900ms;
}


.itemActive:hover {
  color: black;
  background: none !important;
}

.itemActive:hover .itemBtn {
  background-color: white;
  background-image: url("../../../assets/close.svg");
}

.itemBtn {
  color: #3CADFF;
  border: none;
  flex-shrink: 0;
  display: block;
  background-image: url("../../../assets/minus.svg");
  background-color: white;
  background-size: 40px 40px;
  background-position: cover;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.itemBtnActive {
  background-image: url("../../../assets/close.svg");
}

.dataContainer {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  justify-content: center;
  gap: 20px;
}

.dataContainer img {
  max-width: 349px;
  max-height: 289px;
  flex-shrink: 0;
  border-radius: 12px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.textContainer span {
  font-family: "Montserrat";
  font-size: 20px;
}

.textContainer textarea {
  display: flex;
  min-height: 236px;
  max-height: 236px;
  padding: 20px;
  font-size: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: var(--white, #FAFAFA);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
  border: none;
  font-family: "Montserrat";
}

.userContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.userContainer span {
  font-family: "Montserrat";
  font-size: 20px;
}

.userList {
  height: 240px;
  overflow-y: scroll;
  border-radius: 12px;
  border: 1px solid var(--gray, #ACACAC);
  background: var(--white, #FAFAFA);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  padding: 20px;
  color: gray;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.userList::-webkit-scrollbar {
  width: 16px;
}

.userList::-webkit-scrollbar-thumb {
  border-radius: 20px 20px 20px 20px;
  background: var(--gray, #acacac);
}

.userList span {
  font-size: 16px;
}