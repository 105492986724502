@font-face {
    font-family: "Montserrat";
    src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 370px) {
    .containerTitle {
        width: 100px;
        margin-left: 20px;
        align-self: center;
    }
}

@media (max-width: 700px) {
    .bodyRuleDisappear {
        width: auto !important;
    }

    .container {
        align-items: center;

    }

    .form {
        flex-direction: column;
        width: 100%;
        display: flex;
        gap: 80px;
    }

    .formColumn {
        width: 294px;
        align-self: center;
    }

    .formGroup {
        width: 294px;
    }

    .statusBtns {
        flex-direction: column;
        gap: 12px;
    }

    .statusBtns button {
        width: 100%;
    }

    .saveBtn {
        height: 55px;
        width: 284px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .containerTitle {
        flex-shrink: 1;
        align-self: flex-start;
    }

}

@media (min-width: 701px) {
    .container {
        padding: 40px 120px;
        align-items: flex-start;
    }

    .form {
        display: flex;
        width: 50%;
    }

    .formColumn {
        min-width: 400px;
        margin-right: 40px;
    }

    .formGroup {
        min-width: 350px;
    }

    .saveBtn {
        width: 200px;
    }
}

.container {
    width: 100%;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.containerTitle {
    color: var(--black, #282828);

    /* H4 */
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.formContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formColumn {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.formGroup input {
    border-radius: 12px;
    border: none;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    backdrop-filter: blur(2px);
    font-family: "Montserrat";
    padding: 12px 20px;
}

.formGroup textarea {
    display: flex;
    border: none;
    /* width: 284px; */
    max-height: 170px;
    /* max-width: 284px; */
    height: 170px;
    font-family: "Montserrat";
    padding: 12px 20px;
    justify-content: center;
    border-radius: 12px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    backdrop-filter: blur(2px);
    align-items: center;
    gap: 10px;
}

.formGroupTitle {
    display: flex;
    position: relative;
    align-items: center;
    gap: 8px;
}

.number {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    width: 12px;
    border-radius: 12px;
    border: 1px solid var(--white, #FAFAFA);
    background: var(--blue, #0E86F8);
    gap: 10px;
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
}

.formGroupTitle span {
    color: var(--black, #282828);

    /* SubTitle */
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.logoInfo {
    display: flex;
    align-items: center;
    gap: 20px;
}

.logoInfo img {
    width: 82px;
    height: 82px;
    border-radius: 16px;
}

.logoInfo p {
    text-align: left;
    color: var(--black, #282828);

    /* text-tablet */
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.statusBtns {
    display: flex;
}

.statusBtn {
    margin-right: 10px;
    display: flex;
    width: 184px;
    padding: 14px 32px;
    justify-content: center;
    color: #0E86F8;
    border-radius: 18px;
    cursor: pointer;
    font-family: "Montserrat";
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);
}

.statusBtn:hover {
    transition: 200ms;
    border: 1px solid var(--light-blue, #3CADFF);
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);

    /* shadow-button */
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.deliveryOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.deliveryOptions .listItem strong {
    font-weight: bold;
    color: #ff0000;
    background-color: yellow;
    padding: 2px 4px;
}

.activeBtn {
    color: white;
    background-color: #0E86F8;
}

.goTo {
    color: var(--blue, #0E86F8);
    text-align: center;
    font-family: "Montserrat";
    font-size: 16px;
    flex-shrink: 0;
    margin-top: 20px !important;
    display: flex;
    align-items: flex-end;
    margin: auto;
    align-self: flex-end;
    width: 233px;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.saveBtn {
    margin-right: 10px;
    display: flex;
    padding: 10px 32px;
    margin-top: 60px;
    flex-shrink: 0;
    align-self: center;
    color: white;
    border-radius: 18px;
    cursor: pointer;
    font-family: "Montserrat";
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #0E86F8);
}

.saveBtn:hover {
    transition: 200ms;
    border: 1px solid var(--light-blue, #3CADFF);
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);

    /* shadow-button */
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.formGroupTitleExtra {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 120px;

}

.formGroupTitleExtra span {
    color: var(--black, #282828);

    /* SubTitle */
    font-family: "Montserrat";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.listItem {
    display: flex;
    align-items: center;
}

.infoNotification {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 5%;
    left: 65%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px 10px;
    border-radius: 10px;
    background: var(--light-gray, #EEE);
    width: 200px !important;
    z-index: 3;
    overflow: hidden;
    color: var(--black, #282828);
    /* text-align: center; */
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.infoNotification2 {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 20%;
    left: 75%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px 10px;
    border-radius: 10px;
    background: var(--light-gray, #EEE);
    width: 200px !important;
    z-index: 3;
    overflow: hidden;
    color: var(--black, #282828);
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.infoNotification3 {
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 100%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px 10px;
    border-radius: 10px;
    background: var(--light-gray, #EEE);
    width: 200px !important;
    z-index: 100;
    overflow: hidden;
    color: var(--black, #282828);
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.infoLabels {
    margin: 10px;
    display: flex;
    justify-content: center;
    width: 98%;
}

/* Блок с бегунком */
.isFreeBlock {
    position: relative;
    display: flex;
    margin: 16px 0px;
    align-items: flex-start;
    gap: 8px;
    max-width: 320px;
}

.isFreeBlock span {
    color: var(--black, #282828);
    font-family: "Montserrat";
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.isFreeBlock input {
    display: flex;
    max-width: 60px;
    padding: 12px 20px;
    box-sizing: border-box;
    justify-content: center;
    font-size: 16px;
    border: none;
    font-family: "Montserrat";
    align-items: center;
    border-radius: 12px;
    background: var(--white, #fafafa);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    backdrop-filter: blur(2px);
    gap: 10px;
}

.check {
    display: flex;
    align-items: center;
    align-self: center;
}

.isFreeBlockRemember {
    -webkit-appearance: none;
    apperance: none;
    background-color: rgb(255, 255, 255);
    width: 52px;
    height: 27px;
    border: 1px solid #acacac;
    border-radius: 20px;
    position: relative;
    transition-property: background-color;
    transition-duration: 350ms;
}

.isFreeBlockRemember::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    background: #acacac;
    height: 23px;
    width: 24px;
    top: 1px;
    transition-property: left;
    left: 1px;
    transition-duration: 600ms;
    cursor: pointer;
}

.isFreeBlockRemember:checked {
    background-color: #ffffff;
    border: 1px solid #0e86f8;
    transition: 600ms;
}

.isFreeBlockRemember:checked::before {
    left: 25px;
    background-color: #0e86f8;
    transition: 350ms;
}

/* END */