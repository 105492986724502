.item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 20px;
    border: 1px solid var(--light-gray, #EEE);
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    width: 264px;
   
    padding: 20px;
    gap: 20px;
}

.info {
    display: flex;
    gap: 12px;
    align-items: center;
}

.info span {
    color: var(--blue, #0E86F8);

    /* ST2-tablet */
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.img {
    border-radius: 8px;
}

.option {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 3px;
}

.option span:nth-child(1) {
    font-weight: 600;
    font-size: 14px;
}

.priceContainer {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 8px;
}

.price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sale span:nth-child(1) {
    font-weight: 600;
}

.totalPrice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.totalPrice span:nth-child(1) {
    font-weight: 600;
}

.price span:nth-child(1) {
    color: var(--black, #282828);

    /* mob-button */
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--blue, #0E86F8);
    text-align: right;

    /* mob-button */
    font-family: "Montserrat";
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}