.productGroup {
    width: 100%;
}

.groupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    border-top: 1px solid #3CADFF;
    cursor: pointer;
    height: 63px;
}

.groupHeader.expanded {
    transition: 200ms;
}

.groupHeader:hover {
    background-color: #3CADFF;
    color: white;
}

.loader {
    border: 2px solid white;
    padding: 10px;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    border-top: 2px solid #0E86F8;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.toggleButton {
    background: none;
    border: none;
    font-size: 50px;
    font-weight: 200;
    cursor: pointer;
    color: inherit;
}

.groupContent {
    padding: 10px;
    margin-top: -1px;
}

.actions {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    padding-left: 56px;
    flex-wrap: wrap;
}

.actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.actions button.selectAll {
    background-color: #28a745;
    color: white;
}

.actions button.selectAll:hover {
    background-color: #218838;
}

.actions button.deselectAll {
    background-color: #ffc107;
    color: white;
}

.actions button.deselectAll:hover {
    background-color: #e0a800;
}

.actions button.removeSelected {
    background-color: #dc3545;
    color: white;
}

.actions button.removeSelected:hover {
    background-color: #c82333;
}


.productList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
    max-height: 30vh;
    overflow-y: auto;
    padding-left: 56px;
}

.productList::-webkit-scrollbar {
    width: 16px;
}

.productList::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background: var(--gray, #acacac);
}

.productItem {
    position: relative;
    width: 100px;
    height: 120px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #3CADFF;
    padding-top: 8px;
    gap: 12px;
}

.productItem:hover {
    border: 1px solid #ccc;
}

.productItem.selected {
    border-color: rgb(0, 255, 0);
    background-color: #e0ffe0;
    border: 1px solid #3CADFF;
}

/* 
.productItem img {
    width: 40px;
    height: 50px;
    max-width: 80px;
    max-height: 80px;
}

.productItem p {
    margin: 0;
    font-size: 12px;
    text-align: center;
} */

.productItem img {
    width: 40px;
    height: 50px;
    max-width: 80px;
    max-height: 80px;
    height: auto;
    display: block;
}

.productItem p {
    background-color: #3CADFF;
    color: white;
    padding: 10px;
    margin: 0;
    text-align: center;
    font-size: 12px;
    height: 40px;
    width: 100px;
}

.removeButton {
    position: absolute;
    top: -5px;
    right: 0px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: red;
    margin: 0;
}

.addButton {
    position: relative;
    width: 112px;
    height: 132px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    font-weight: 100;
    font-size: 50px;
}

.addButton:hover {
    background-color: #3CADFF;
    color: white;
}

.labelSection,
.discountSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    margin: 10px;
}

.labelSection h2,
.discountSection h2 {
    border-radius: 12px;
    background: var(--light-blue, #3CADFF);
    color: white;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    padding: 10px 20px;
}

.labelManagement,
.discountManagement {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 12px 0px;
    align-items: center;
    justify-content: center;
}

.labelText {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.discountText {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
}

.infoIcon {
    margin: 0 2px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    color: #419fd9;
    position: relative;
}

.tooltip {
    visibility: hidden;
    width: 200px;
    background: var(--light-gray, #EEE);
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.infoIcon:hover+.tooltip {
    visibility: visible;
    opacity: 1;
}

.labelInput {
    max-width: 120px;
    border-radius: 12px;
    background: #fff;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border: none;
    width: calc(100% - 20px);
}

.labelStyle {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.labelSelect {
    padding: 4px 8px;
    border-radius: 12px;
    cursor: pointer;
    width: 200px;
    height: 46px;
}

.labelPreviewBlock {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-width: 160px;
}

.labelPreview {
    text-align: center;
    border-radius: 12px;
    padding: 5px 0;
    font-size: 14px;
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: end;
    gap: 6px;
}

.buttons button {
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    display: flex;
    padding: 12px 28px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 15px;
    color: white;
    max-width: 120px;
    transition: 200ms;
}

.buttons button:hover {
    opacity: 0.8;
}

@media (max-width: 701px) {

    .productItem,
    .addButton {
        width: 200px;
        width: 240px;
    }

    .productList {
        justify-content: center;
    }
}