@font-face {
  font-family: "Montserrat";
  src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
  .answers {
    padding: 12px 40px;
  }
}

@media (min-width: 701px) {
  .answers {
    padding: 40px 120px;
  }
}

.answers {
  box-sizing: border-box;
  width: 100%;
  /* margin-top: 100px; */
  display: flex;
  flex-direction: column;
}

.answersTitle {
  color: var(--light-blue, #3CADFF);
  text-align: left;

  /* H3-tablet */
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.answersContainer {
  width: 100%;
}