.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 10px;
  background-color: #3cadff;

  width: 100%;

  color: #fafafa;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  border-radius: 12px;
  text-align: center;
}

.variationForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
}

.variationForm>div {
  flex-basis: 30%;
}