.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination span {
    margin: 0 5px;
    padding: 0px 2px;
    cursor: pointer;
}

.pagination span.active {
    text-decoration: underline;
}