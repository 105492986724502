.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  max-width: 300px;
  column-gap: 18px;
}

.loading {
  position: absolute;
  font-size: 20px;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  font-weight: 800;
    top: 50%; /* Положение по вертикали */
    left: 50%; /* Положение по горизонтали */
    transform: translate(-50%, -50%);
}




