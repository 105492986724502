.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 15px 20px;
    border-radius: 18px;
    background: var(--white, #fafafa);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
}

.containerRaw {
    display: flex;
    font-family: "Montserrat";
    gap: 12px;
    flex-wrap: wrap;
    align-items: center;
}

.containerRaw span:nth-child(1) {
    font-size: 18px;
    font-weight: 600;
    flex-shrink: 0;
}

.containerRaw .linkStyle {
    display: flex;
    flex-shrink: 0;
    flex-wrap: nowrap;
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    color: #3cadff;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.containerRaw a:hover {
    color: #5486a9;
}

.copyLink {
    cursor: pointer;
}