@font-face {
    font-family: "Montserrat";
    src: url("../../assets/Montserrat-VariableFont_wght.ttf");
}

body {
    font-family: "Montserrat";
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.confirmation-page {
    text-align: center;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;
}

.logo {
    margin-bottom: 20px;
}

.logo img {
    max-width: 100px;
    height: auto;
}

.confirmation-page h1 {
    margin-bottom: 20px;
    color: #333;
}

.confirmation-page p {
    margin-bottom: 30px;
    color: #666;
}

.confirmation-page a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.confirmation-page a:hover {
    color: #0056b3;
}

.confirmation-page .success {
    color: #4caf50;
}

.confirmation-page .error {
    color: #f44336;
}