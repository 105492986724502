.container {
    min-height: 67vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 120px;
    gap: 24px;
}

.productGroups {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productGroups .title {
    color: #282828;
    font-family: "Montserrat";
    align-self: flex-start;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

@media (max-width: 701px) {
    .container {
        margin: 20px 40px;
    }
}