@font-face {
    font-family: "Montserrat";
    src: url("../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
    .bodyRuleDisappear {
        width: auto !important;
    }

    .container {
        box-sizing: border-box;
    }

    .productsContainer {
        display: none;
    }

    .productsMobileContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
    }

    .addButtonsMobile {
        display: flex;
        align-items: center;
        gap: 12px;
        font-family: "Montserrat";
        margin-top: 20px;
    }

    .addButtonsMobile button {
        min-width: 244px;
        width: 100%;
    }

    .infoText {
        width: 310px;
        align-self: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .addMobileCont {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .searchContainer {
        align-self: center;
        justify-self: center;
        width: 340px;
    }
}

@media (min-width: 701px) {

    .products {
        padding: 40px 0px;
    }

    .productsContainer {
        display: flex;
    }

    .productsMobileContainer {
        display: none;
    }

    .container .infoText {
        font-family: "Montserrat";
        align-self: flex-start;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 5px;
    }

    .searchContainer {
        align-self: flex-start;
    }
}

.container {
    display: flex;
    flex-direction: column;
}

.products {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
}

.productsTitle {
    color: #282828;
    font-family: "Montserrat";
    align-self: flex-start;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.productsAdd {
    gap: 12px;
    color: #3CADFF;
    background-color: transparent;
    border: none;
    justify-content: flex-end;
    font-family: "Montserrat";
    cursor: pointer;
}

.productsAdd:hover {
    color: #0c79c6;
    transition: 200ms;
}

.productsAdd span {
    font-size: 16px;
}

.productsContainer {
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    align-self: flex-start;
    box-sizing: border-box;
    gap: 12px;
}

.productsInfo {
    display: flex;
    max-width: 1200px;
    padding: 20px;
    align-items: center;
    background-color: #EEE;
    border-radius: 16px;
}

.productsInfo div {
    display: flex;
    align-items: center;
}

.productsInfo div:nth-child(1) {
    width: 140px;
}

.productsInfo div:nth-child(2) {
    width: 100px;
}

.productsInfo div:nth-child(3) {
    width: 220px;
}

.productsInfo div:nth-child(4) {
    width: 120px;
    margin-right: 55px;
}

.productsInfo div:nth-child(5) {
    width: 120px;
    margin-right: 40px;
}

.productsInfo div:nth-child(6) {
    width: 160px;
}

.productsInfo div:nth-child(7) {
    width: 160px;
}


.productsInfo div img {
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.productsInfo div span {
    font-family: "Montserrat";
    font-size: 18px;
}

.emptyMessage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.emptyMessage span {
    font-family: "Montserrat";
    text-align: center;
}

.emptyMessage button {
    font-family: "Montserrat";
    padding: 14px 32px;
    color: #3CADFF;
    border-radius: 18px;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);
}

.emptyMessage button:hover {
    background: #3CADFF;
    color: white;
    transition: 200ms;
}

.addBtn {
    font-family: "Montserrat";
    flex-shrink: 0 !important;
    width: 180px;
    border: none;
    cursor: pointer;
    padding: 14px 32px;
    gap: 10px;
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    color: white;
}

.addFileBtn {
    font-family: "Montserrat";
    flex-shrink: 0 !important;
    width: 220px;
    border: none;
    cursor: pointer;
    padding: 14px 32px;
    gap: 10px;
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    color: white;
    align-self: center;
}

.addButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: "Montserrat";
}

.addBtn:hover {
    transition: 200ms;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.addFileBtn:hover {
    transition: 200ms;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}


.productMenu {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 20px;
    /* justify-content: end; */
}

.productMenu button {
    border: none;
    cursor: pointer;
    display: flex;
    align-self: center;
    padding: 14px 32px;
    justify-content: center;
    border: 1px solid black;
    border-radius: 18px;
    align-items: center;
    gap: 10px;
    font-family: "Montserrat";
    font-weight: 500;
    background: var(--white);
}

.productMenu span {
    color: #3CADFF;
    cursor: pointer;
}

.productMenu span:hover {
    color: #0c79c6;
    transition: 200ms;
}


.searchContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.searchContainer input {
    padding: 12px 8px 12px 8px;
    padding-left: 28px;
    border-radius: 12px;
    width: 300px;
    font-family: "Montserrat";
    background-image: url("../assets/icons8-лупа.svg");
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 5px center;
    border: 1px solid #797878;
}


.btnsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.containerSearchReset {
    position: relative;
}


.searchInputResetBtn {
    position: absolute;
    right: 5px;
    top: 6px;
    background-image: url("../assets/icons8-крестик-78.png");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: transparent;
    border: none;
}



