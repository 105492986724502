@font-face {
  font-family: "Montserrat";
  src: url("../assets/Montserrat-VariableFont_wght.ttf");
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdownToggle {
  padding: 12px 24px;
  border: 1px solid #3CADFF;
  background-color: transparent;
  margin-left: 20px;
  font-size: 20px;
  font-family: "Montserrat";
  min-width: 170px;
  cursor: pointer;
  border-radius: 18px;
}

.dropdownMenu {
  display: block;
  list-style: none;
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #3CADFF;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(14px);
  border-radius: 20px;
  overflow: hidden;
  z-index: 5;
}

.dropdownMenu li {
  background: #fff;
  box-sizing: border-box;
  padding: 10px 10px;
  margin-bottom: 0px !important;
  border-bottom: 1px solid #3CADFF;
  font-size: 20px;
  font-family: "Montserrat";
  cursor: pointer;
}

.dropdownMenu li:last-child {
  border-bottom: none;
}

.dropdownMenu li:hover {
  background: linear-gradient(0deg, rgba(60, 173, 255, 0.20) 0%, rgba(60, 173, 255, 0.20) 100%), #FAFAFA;
}

.extra {
  padding: 15px 24px 15px 35px;
  border: 1px solid #3CADFF;
  background-color: transparent;
  margin-left: 20px;
  color: #3CADFF;
  font-size: 16px;
  background-image: url("../assets/61.png");
  background-repeat: no-repeat;
  background-position: 5px;
  font-family: "Montserrat";
  border-radius: 18px;
}

.extra:hover {
  background-color: #3CADFF;
  color: #f1f1f1;
}
