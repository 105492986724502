.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 183px;
  padding: 18px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid white;
  background: white;
  position: relative;
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  font-family: "Montserrat";
}

.activeCard {
  background: #3cadff;
  color: #fafafa;
  border: 1px solid #3cadff;
}

.card:hover {
  border: 1px solid #3cadff;
  animation: borderAnimation 1s forwards;
}

@keyframes borderAnimation {
  from {
    border: 1px solid white;
  }

  to {
    border: 1px solid #3cadff;
  }
}

.card button {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 20px;
  right: 15px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-image: url("../../../../../assets/Frame\ 872-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card button:hover {
  background-image: url("../../../../../assets/Property\ 1=cancel-hover.png");
  width: 14px;
  transition: 200ms;
  height: 14px;
}

.popupBtnBack {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  font-family: "Montserrat";
  cursor: pointer;
  font-size: 16px;
  padding: 14px 32px;
  color: #3cadff;
}

.popupBtnBack:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupBtnDel {
  border-radius: 18px;
  background: var(--blue, #0e86f8);
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 16px;
  color: white;
}

.popupBtnDel:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupClose {
  position: absolute;
  width: 16px !important;
  min-width: 40px;
  max-width: 40px;
  height: 16px;
  top: 20px;
  right: 10px;
  border: none;
  background-color: transparent;
  background-image: url("../../../../../assets/Frame\ 872.png");
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media (max-width: 700px) {
  .card {
    width: 100%;
  }
}