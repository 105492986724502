.wrapper {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 12px;
  padding-top: 20px;
}

.wrapper span {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
}

.wrapper div span {
  font-weight: 400;
  justify-self: flex-end;
}
