@media (max-width: 700px) {
  .bodyRuleDisappear {
    width: auto !important;
  }

  .container {
    padding: 12px 40px;
  }

  .info {
    width: 100%;
    align-items: center;
  }

  .days {
    flex-wrap: wrap;
    width: 294px;
  }

  .days button {
    width: 136px;
    height: 41px;
  }

  .numbers {
    flex-direction: column;
  }

  .numbersItem {
    width: 326px;
  }

  .numbersItem span:nth-child(2) {
    font-size: 18px;
  }

  .storage {
    display: none;
  }

  .ordersContainer {
    align-self: center;
    width: 284px;
  }

  .ordersHeader {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .countOnPage {
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
  }

  .countOnPage ul {
    padding: 0;
  }

  .searchWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .searchWrapper input {
    font-size: 14px;
    width: 280px;
  }

  .itemsCont {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }


}

@media (min-width: 701px) {
  .container {
    /* padding: 40px 120px; */
    padding: 40px 20px;
  }

  .info {
    width: 1320px;
  }

  .numbersItem {
    width: 330px;
  }

  .numbersItem span:nth-child(2) {
    font-size: 22px;
  }

  .itemCont {
    display: none;
  }

  .itemsCont {
    display: none;
  }

  .ordersContainer {
    width: 1320px;
    max-width: 1320px;
    min-width: 1320px;
    align-self: flex-start;
  }

  .ordersHeader {
    align-items: center;
  }

  .countOnPage {
    align-items: center;
    align-self: flex-end;
  }

  .searchWrapper {
    align-items: center;
  }

  .searchWrapper input {
    font-size: 16px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  justify-content: center;
  overflow-x: auto;
}

.title {
  align-self: flex-start;
  color: var(--black, #282828);

  /* H4 */
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.info {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
}

.days {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 20px;
}

.days button {
  border-radius: 12px;
  border: 1px solid var(--light-blue, #3cadff);
  display: flex;
  font-family: "Montserrat";
  color: #3cadff;
  padding: 8px 24px;
  cursor: pointer;
  background: white;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.days button:hover {
  border: 1px solid var(--light-blue, #3cadff);
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  /* shadow-button */
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
  color: white;
  transition: 200ms;
}

.activeDay {
  transition: 200ms;
  background-color: #3cadff !important;
  color: white !important;
}

.numbers {
  display: flex;
  align-items: center;
  gap: 20px;
}

.numbersItem {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 18px;
  align-items: flex-start;
  border: 1px solid #3cadff;
  padding: 24px 30px;
  font-family: "Montserrat";
}

.numbersItem span:nth-child(2) {
  color: var(--light-blue, #3cadff);

  /* H4 */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.ordersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ordersHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.searchWrapper {
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  gap: 10px;
  margin: 12px;
  display: flex;
  font-weight: 400;
  line-height: normal;
}

.searchWrapper input {
  padding: 12px 8px 12px 8px;
  border-radius: 12px;
  font-family: "Montserrat";
  color: var(--gray, #ACACAC);
  border: 1px solid #797878;
  /* background-color: transparent !important; */
}

.countOnPage {
  display: flex;
}

.countOnPage ul {
  display: flex;
  list-style: none;
  gap: 20px;
  flex-shrink: 0;
  font-family: "Montserrat";
}

.countOnPage ul li {
  cursor: pointer;
  flex-shrink: 0;
}

.activePage {
  text-decoration: underline;
  font-weight: 600;
}

.storage {
  border: 1px solid black;
  border-radius: 16px;
  overflow: hidden;
}

.ordersInfo {
  display: flex;
  width: 1320px;
  padding: 20px 24px;
  max-width: 1320px;
  min-width: 1320px;
  box-sizing: border-box;
  background-color: #eee;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  font-weight: 600;
}

.ordersInfo div {
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  background: transparent;
  align-items: center;
  justify-content: center;
}

.ordersInfo div:nth-child(1) {
  width: 220px;
  flex-shrink: 0;
  text-align: center;
}

.ordersInfo div:nth-child(2) {
  width: 232px;
}

.ordersInfo div:nth-child(3) {
  width: 165px;
  text-align: center;
}

.ordersInfo div:nth-child(4) {
  width: 172px;
}

.ordersInfo div:nth-child(5) {
  width: 152px;
  text-align: left;
}

.ordersInfo div:nth-child(6) {
  width: 160px;
}

.ordersInfo div img {
  width: 28px;
  height: 28px;
}

.ordersInfo div span {
  font-family: "Montserrat";
  font-size: 18px;
}

.pages {
  align-self: flex-end;
  display: flex;
  align-items: center;
  font-family: "Montserrat";
}

.pages ul {
  flex-shrink: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  font-family: "Montserrat";
}

.pages ul li {
  cursor: pointer;
  flex-shrink: 0;
}

.pages ul li:hover {
  color: #3cadff;
  transition: 200ms;
  text-decoration: underline;
}