@font-face {
    font-family: "Montserrat";
    src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {

    .bodyBlock {
        width: auto !important;
    }

    .container {
        padding: 12px 40px;
    }

    .chooseContainer {
        flex-direction: column;
    }

    .chooseItem {
        width: 320px;
    }



    .chooseItem img {
        width: 320px;
        height: 360px;
    }

    .chooseItem p {
        width: 320px !important;
    }

    .chooseItem button {
        align-self: center;
    }
}

@media (min-width: 701px) {

    .container {
        padding: 40px 120px;
    }

    .chooseItem {
        width: 30%;
    }

    .chooseItem img {
        width: 421.383px;
        height: 356.502px;
    }

    .text {
        font-size: 20px;
    }
}


.megaContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 1500px;
    align-items: center;
    gap: 40px;
    width: 100%;
    box-sizing: border-box;
}

.title {
    color: var(--black, #282828);
    align-self: flex-start;
    /* H4 */
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.text {
    color: var(--black, #282828);
    align-self: flex-start;
    /* SubTitle */
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chooseContainer {
    display: flex;
    justify-self: center;
    align-self: center;
    gap: 122px;
}

.chooseItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.chooseItem img {
    flex-shrink: 0;
}

.chooseItem span {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;

    /* ST2 */
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.chooseItem p {
    color: var(--black, #282828);

    /* text */
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chooseItem button {
    display: flex;
    width: 260px;
    height: 49px;
    padding: 14px 32px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: none;
    color: white;
    font-family: "Montserrat";
    border-radius: 18px;
    background: var(--blue, #0E86F8);
}

.chooseItem button:hover {

    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}