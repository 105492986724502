.base {
    display: none !important;
  }
  .custom {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
  }
  
  .custom::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    /* Ширина кастомной галочки */
    height: 20px;
    /* Высота кастомной галочки */
    border: 1px solid #333;
    border-radius: 6px;
    /* Цвет рамки */
  }
  
  .custom::after {
    content: "✔";
    font-size: 18px;
    color: #3cadff;
    position: absolute;
    left: 5px;
    top: -2px;
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  /* Стилизация галочки при ее выборе */
  .base:checked + .custom::after {
    opacity: 1;
  }