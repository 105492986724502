@media (max-width: 700px) {
    .text {
        width: 250px;
    }
}

@media (min-width: 701px) {
    .text {
        width: 286px;
    }
}


.text {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    color: #ACACAC;
    border-radius: 12px;
    border: 1px solid var(--gray, #ACACAC);
    background: var(--white, #FAFAFA);
}