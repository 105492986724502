.starRating {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}


.starRating span {
    margin-left: 8px;
}

.starRating .star {
    display: inline-block;
    width: 22px;
    height: 22px;
  }

  .yellow {
    color: yellow;
  }
  

  
  .star-rating .star.half {
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
  }