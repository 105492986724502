@media (max-width: 700px) {
    .container {
        padding: 12px 40px;
    }

    .backBtn {
        display: none;
    }

    .info {
        flex-direction: column;
        align-items: flex-start;
    }

    .orderPaymentInfo {
        flex-direction: column;
    }

    .orderStorage {
        flex-direction: column;
        align-self: center;
        width: 100%;
    }

    .orderDescription {
        align-self: flex-start;
    }

    .orderShippingText {
        flex-direction: column;
    }

    .emergancy {
        display: none;
    }

    .orderStorageContainer .orderBlocks:nth-child(2) {
        flex-direction: column-reverse;
    }

    .orderStorageContainer .orderBlocks:nth-child(3) {
        flex-direction: column;
    }

    .bodyRuleDisappear {
        width: auto !important;
    }

    .ordersItems {
        display: none;
    }

    .orderShippingBlock {
        margin-bottom: 20px;
        flex-wrap: wrap;
    }


    .mobileOrderContainer {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .mobileOrderContainerTitle {
        color: var(--black, #282828);
        /* H3-tablet */
        font-family: "Montserrat";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
    }
}

@media (min-width: 701px) {

    .container {
        padding: 40px 120px;
    }

    .backBtn {
        display: flex;
    }

    .info {
        align-items: center;
    }

    .orderStorage {
        width: 100%;
        margin-top: -260px;
    }

    .ordersItems {
        display: flex;
        margin-top: -100px;
    }

    .mobileOrderContainer {
        display: none;
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    max-width: 1500px;
    box-sizing: border-box;
}

.backBtn {
    align-items: center;
    text-decoration: none;
    align-self: flex-start;
    font-family: "Montserrat";
    color: #747474;
    gap: 8px;
}

.backBtn a {
    text-decoration: none;
    font-family: "Montserrat";
    color: #747474;
}

.title {
    color: var(--black, #282828);

    /* H4 */
    font-family: "Montserrat";
    align-self: flex-start;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    /* margin-bottom: 40px; */
    line-height: normal;
    text-transform: uppercase;
}

.info {
    color: var(--black, #282828);

    /* ST2 */
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    display: flex;
    align-self: flex-start;
    font-weight: 600;
    line-height: normal;
}

.info span {
    color: #0E86F8;
}

.info span:nth-child(2) {
    color: #282828;
    margin-left: 15px;
    font-weight: 400;
}

.orderStorage {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderDescription {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.orderPayment {
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    align-self: flex-start;
    gap: 20px;
}

.orderPaymentTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--black, #282828);

    /* ST2 */
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.orderPaymentInfo {
    gap: 8px;
    display: flex;
}

.orderPaymentInfo span {
    font-family: "Montserrat";
    font-size: 16px;
}

.orderPaymentInfo span:nth-child(2) {
    font-weight: 600;
}

.orderPaymentInfo span:nth-child(3) {
    color: gray;
}

.orderShipping {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.orderShippingTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat";
}

.orderShippingText {
    display: flex;
    gap: 60px;
}

.orderShippingTextColumn {
    display: flex;
    font-family: "Montserrat";
    flex-direction: column;
    gap: 50px;
}

.orderShippingBlock {
    gap: 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.orderShippingBlock span {
    color: gray;
}

.orderShippingBlock p {
    margin: 0px;
}

.orderStorageContainer {
    display: flex;
    gap: 40px;
    margin-bottom: 120px;
    flex-direction: column;
    align-items: flex-end;
}

.emergancy {
    width: 284px;
    font-size: 14px;
}

.emergancyText {
    color: red;
    font-weight: 600;
}


.orderBlocks {
    display: flex;
    gap: 20px;
}

.orderRating {
    display: flex;
    min-height: 298px;
    width: 238px;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 20px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    gap: 20px;
}


.orderRatingTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    font-size: 18px;
}


.orderRatingComment {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.orderRatingComment span {
    color: var(--not-main-action, #747474);

    /* text */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}




.orderStatus {
    display: flex;
    border-radius: 20px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    flex-direction: column;
    width: 238px;
    padding: 20px;
    align-items: flex-end;
    gap: 20px;
}

.orderStatusInfo {
    display: flex;
    align-self: flex-end;
    gap: 10px;
    align-items: flex-end;
    flex-direction: column;
}

.orderStatusInfo div:last-child .point::after {
    display: none;
}


.orderBackBlock {
    display: flex;
    border-radius: 20px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
    flex-direction: column;
    width: 232px;
    padding: 20px;
    align-items: flex-end;
    gap: 20px;
}

.orderBackBlockTitle {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 8px;
}

.itemCancelStatus {
    display: flex;
    color: #747474;
    align-items: center;
    gap: 8px;
}


.itemCancelStatus .point {
    width: 18px;
    height: 18px;
    background-image: url("../../../assets/cross_gray.svg");
    background-size: cover;
}

.itemActiveCancelStatus {
    display: flex;
    color: black;
    align-items: center;
    gap: 8px;
}

.itemActiveCancelStatus .point {
    width: 16px;
    height: 16px;
    background-image: url("../../../assets/red_cross.png");
    background-size: cover;
}


.itemNoramlStatus {
    display: flex;
    align-items: center;
    gap: 8px;
}

.itemNoramlStatus .point {
    width: 16px;
    height: 16px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #0E86F8;
}

.itemNoramlStatus .point::after {
    content: "";
    display: block;
    border-left: 2px dashed #0E86F8;
    height: 12px;
    width: 12px;
    margin-top: 15px;
    margin-left: 7px;
}

.itemActiveStatus {
    display: flex;
    align-items: center;
    gap: 8px;
}

.itemActiveStatus .point {
    width: 16px;
    cursor: pointer;
    height: 16px;
    display: block;
    border: 1px solid #0E86F8;
    border-radius: 50%;
    background-color: #0E86F8;
}

.itemActiveStatus .point::after {
    content: "";
    display: block;
    border-left: 2px dashed #0E86F8;
    height: 12px;
    width: 12px;
    margin-top: 14px;
    margin-left: 7px;
}

.itemNonActive {
    display: flex;
    align-items: center;
    gap: 8px;
    color: gray;
}

.itemNonActive .point {
    width: 16px;
    cursor: pointer;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid gray;
}

.itemNonActive .point::after {
    content: "";
    display: block;
    border-left: 2px dashed gray;
    height: 12px;
    width: 12px;
    margin-top: 15px;
    margin-left: 7px;
}

.ordersItems {
    flex-direction: column;
    align-items: center;
    width: 1200px;
    max-width: 1200px;
    gap: 20px;
    min-width: 1200px;
}

.cost {
    align-self: flex-end;
    display: flex;
    height: 154px;
    padding: 20px;
    gap: 20px;
    align-items: flex-end;
    flex-direction: column;
    width: 243px;
    border-radius: 20px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.costItem:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 253px;
    font-weight: 600;
    font-family: "Montserrat";
    color: #0E86F8;
    flex-direction: row;
    padding-bottom: 5px;
    border-bottom: 2px solid #0E86F8;
}

.costItem {
    display: flex;
    width: 253px;
    flex-direction: column;
    align-self: flex-end;
    align-items: flex-end;
    justify-content: space-between;
}

.costItem span:nth-child(2) {
    font-weight: 600;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 20px;
}

.table {
    width: 1200px;
    display: flex;
    align-items: center;
    background-color: #0E86F8;
    color: white;
    font-family: "Montserrat";
    padding: 14px 20px;
    box-sizing: border-box;
    font-size: 18px;
    border-radius: 20px 20px 0px 0px;
    font-weight: 600;
}

.table div {
    margin: auto;
    display: flex;
    text-align: center;
    align-items: center;
}

.table div:nth-child(1) {
    width: 380px;
    text-align: center;
}


.table div:nth-child(2) {
    width: 124px;
    text-align: left;
}


.table div:nth-child(3) {
    width: 120px;
    text-align: left;
}

.table div:nth-child(4) {
    width: 122px;
    text-align: center;
}

.table div:nth-child(5) {
    width: 144px;
    text-align: center;
}

.table div:nth-child(6) {
    width: 112px;
    text-align: center;
}


.popupBtnBack {
    border-radius: 18px;
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);
    font-family: 'Montserrat';
    cursor: pointer;
    font-size: 16px;
    padding: 14px 32px;
    color: #3CADFF;
}


.popupBtnBack:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.popupBtnDel {
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    padding: 14px 32px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 16px;
    color: white;
}

.popupBtnDel:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}


.popupClose {
    position: absolute;
    width: 16px !important;
    min-width: 40px;
    max-width: 40px;
    height: 16px;
    top: 20px;
    right: 10px;
    border: none;
    background-color: transparent;
    background-image: url("../../../assets/Frame\ 872.png");
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    cursor: pointer;
}