.loadingOverlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 9999;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.loaderContainer span {
    color: #fff;
}

.loader {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
    position: relative;
}

.centerImage {
    position: absolute;
    top: calc(50% + 15px);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadingOverlay.show {
    opacity: 1;
}