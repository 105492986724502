.title {
    width: 284px;
    align-self: flex-start;
    color: var(--black, #282828);
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}


.tariffContainer {
    display: flex;
    gap: 20px;
    width: 100%;
}

.tarif {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    border-radius: 30px;
    border: 1px solid var(--light-blue, #3cadff);
    background-color: var(--white, #fafafa);
    background-position: right top;
    background-repeat: no-repeat;
    position: relative;
}

.tarif:nth-child(2) {
    background-image: url("../../../assets/55.png");
}

.tarif:nth-child(2) .oldSalePrice {
    margin-top: 40px;
}

.tarif::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fafafa;
    opacity: 0.6;
}

.tarifTitle {
    color: var(--black, #282828);
    text-align: center;

    /* H4 */
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    position: relative;
    z-index: 3;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.saleText {
    color: var(--light-blue, #3cadff);
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    z-index: 3;
    line-height: normal;
    text-align: center;
}

.oldSalePrice {
    color: gray;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    margin-bottom: -40px;
    z-index: 3;
    text-decoration: line-through;
    line-height: normal;
    text-align: center;
}

.activeTarif {
    background: #0e86f8 !important;
    color: #fafafa !important;
}

.advantage {
    margin-top: 15px;
    position: relative;
    z-index: 3;
    display: flex;
    gap: 6px;
}

.advantage img {
    width: 22px;
    height: 20px;
}

.sale {
    color: var(--gray, #acacac);
    font-size: 16px;
    font-style: normal;
    align-self: flex-start;
    margin-top: 40px;
    font-weight: 400;
    line-height: normal;
}

.cancel {
    align-self: flex-start;
    display: flex;
    margin-top: 20px;
    gap: 4px;
}

.cancelBtn {
    text-decoration: underline;
    cursor: pointer;
}

.popupBtnBack {
    border-radius: 18px;
    border: 1px solid var(--light-blue, #3cadff);
    background: var(--white, #fafafa);
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 16px;
    z-index: 3;
    position: relative;
    padding: 14px 32px;
    color: #3cadff;
}

.freeButton {
    border-radius: 18px;
    border: 1px solid var(--light-blue, #3cadff);
    background: var(--white, #fafafa);
    font-family: "Montserrat";
    cursor: pointer;
    font-size: 16px;
    z-index: 3;
    position: relative;
    padding: 14px 32px;
    color: #3cadff;
}

.popupBtnBack:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
        0px 3px 6px 0px rgba(17, 17, 34, 0.18),
        0px 11px 11px 0px rgba(17, 17, 34, 0.15),
        0px 24px 14px 0px rgba(17, 17, 34, 0.09),
        0px 43px 17px 0px rgba(17, 17, 34, 0.03),
        0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupBtnPay {
    border-radius: 18px;
    background: var(--blue, #0e86f8);
    border: none;
    padding: 14px 32px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
}

.popupBtnPay:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
        0px 3px 6px 0px rgba(17, 17, 34, 0.18),
        0px 11px 11px 0px rgba(17, 17, 34, 0.15),
        0px 24px 14px 0px rgba(17, 17, 34, 0.09),
        0px 43px 17px 0px rgba(17, 17, 34, 0.03),
        0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupClose {
    position: absolute;
    width: 16px !important;
    min-width: 40px;
    max-width: 40px;
    height: 16px;
    top: 20px;
    right: 10px;
    border: none;
    background-color: transparent;
    background-image: url("../../../assets/Frame\ 872.png");
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.popupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.popupTitle {
    text-align: center;
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
}

.popupContent {
    text-align: center;
    font-family: "Montserrat";
    font-size: 14px;
}

.popupFooter {
    display: flex;
    align-items: center;
    gap: 20px;
}

@media (max-width: 700px) {
    .title {
        width: 100%;
        text-align: center;
    }

    .tariffContainer {
        flex-wrap: wrap;
        justify-content: center;
    }

    .tarif {
        padding: 30px 20px;
        width: 326px;
        font-size: 14px;
        height: 520px;
    }

    .saleText {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .cancel {
        flex-direction: column;
        display: flex;
    }
}

@media (min-width: 701px) {
    .tarif {
        height: 545px;
        padding: 40px 16px;
        width: 558px;
        font-size: 18px;
    }

    .saleText {
        font-size: 22px;
    }
}