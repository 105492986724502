.videos {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}

.videosTitle {
  color: black;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 60px;
  text-transform: uppercase;
}

.videosTitle span {
  color: #3CADFF;
}

.videosContainer {
  display: flex;
  justify-content: space-between;
}

.videosContainerList {
  display: flex;
  flex-direction: column;
  margin: auto;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.videosContainerListTitle {
  margin-bottom: 20px;
  font-family: 400;
  font-size: 20px;
}

.videosContainerListItems {
  list-style: none;
  padding: 0;
}

.videosContainerListItem {
  padding: 12px 20px;
  border-radius: 12px;
  margin-bottom: 12px;
  margin-right: 0;
  background: var(--white, #FAFAFA);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.videosContainerListItem:hover {
  cursor: pointer;
  background-color: #0E86F8;
  color: white;
  transition: 600ms;
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.videosContainerListItemActive {
  cursor: pointer;
  background-color: #0E86F8;
  color: white;
  transition: 600ms;
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}


.videosContainerListPlayer {
  border: 2px solid gray;
  margin-left: 30px;
  border-radius: 20px;
  overflow: hidden;
  height: 324px;
  border-radius: 20px;
}

@media (max-width: 700px) {
  .videos {
    padding: 12px 40px;
    align-self: center;
    width: 100%;
  }

  .videosContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .videosContainerListPlayer {
    width: 100%;
    margin: 0px;
  }
}

@media (min-width: 701px) {
  .videos {
    padding: 40px 120px;
    width: 100%;
  }

  .videosContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .videosContainerList {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
  }

  .videosContainerListItems {
    width: 100%;
  }

  .videosContainerListPlayer {
    width: 40%;
    max-width: 800px;
  }
}