@font-face {
  font-family: "Montserrat";
  src: url("../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
  .footer {
    flex-direction: column;
    gap: 30px;
    padding: 12px 40px 12px 40px;
    width: 100%;
    height: 550px;
  }

  .footerLink {
    font-size: 14px;
    display: flex;
  }
}

@media (min-width: 701px) {
  .footer {
    flex-direction: row;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 120px;
    gap: 20px;
    width: 100%;
  }

  .footerCol:nth-child(3) .footerRow a {
    font-size: 18px;
  }

}

.footer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #0e86f8;

  box-sizing: border-box;

}

.footerRow:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
  gap: 8px;
}

.footerRow:nth-child(1) span:nth-child(1) {
  font-family: "Montserrat";
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
}

.footerRow:nth-child(1) span:nth-child(2) {
  font-family: "Montserrat";
  font-size: 16px;
  color: white;
}

.footerRow:nth-child(2) {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footerRow:nth-child(2) a {
  text-decoration: none;
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
  align-items: center;
}

.footerCol:last-child {
  padding-right: 120px;
  align-items: flex-start;
}

.footerCol:nth-child(3) .footerRow {
  gap: 16px;
}

.footerCol:nth-child(3) .footerRow a {
  text-decoration: none;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  height: 26px;
}

.footerCol:nth-child(3) .footerRow a:first-child {
  margin-top: 20px;
  margin-left: 5px;
}

.footerRow:last-child img {
  justify-content: center;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  margin-bottom: 4px;
}

.footerRow img::after {
  content: " ";
}

.partnerText {
  text-decoration: none;
  color: white;
  font-family: "Montserrat";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-items: center;
  height: 26px;
}

.partnerLogo {
  width: 250px;
  height: 62px;
  margin-top: 10px;
  background-image: url("../assets/boxberry_footer_logo.svg");
  background-size: 250px 62px;
  background-position: center;
  flex-shrink: 0;
}