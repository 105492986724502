@media (max-width: 700px) {
    .bodyRuleDisappear {
        width: auto !important;
    }
}


.store-management-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

.store-cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-store-card {
    border: 1px dashed #ccc;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 16px;
    transition: background-color 0.3s;
}

.add-store-card:hover {
    background-color: #f0f0f0;
}