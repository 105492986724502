.wrapper {
  width: 320px;
}

.title {
  position: relative;
  bottom: 12px;
}

.inputContainer {
  display: flex;
  align-items: center;
  margin-top: 36px;
  gap: 20px;
}

.inputContainer button {
  border: none;
  width: 60px;
  height: 60px;
  background-image: url("../../../../../assets/icon\ addPlus.png");
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 50px 44px;
  background-position: left;
  background-color: transparent;
}

.inputContainer button:hover {
  background-image: url("../../../../../assets/Property\ 1=add-hover.png");
  background-size: 60px 65px;
}

.inputContainer input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: 284px;
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.listItem {
  display: flex;
  margin: 0;
  width: 200px !important;
  align-items: baseline;
  font-size: 20px;
  font-family: "Montserrat";
  gap: 8px;
}

.listItem input {
  width: auto;
}

.listItem button {
  width: 10px;
  height: 10px;
  background-color: transparent;
  cursor: pointer;
  background-image: url("../../../../../assets/Frame\ 872-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px 10px;
  border: none;
}

.popupBtnBack {
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
  font-family: "Montserrat";
  cursor: pointer;
  font-size: 16px;
  padding: 14px 32px;
  color: #3cadff;
}

.popupBtnBack:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupBtnDel {
  border-radius: 18px;
  background: var(--blue, #0e86f8);
  border: none;
  padding: 14px 32px;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 16px;
  color: white;
}

.popupBtnDel:hover {
  transition: 200ms;
  color: white;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}

.popupClose {
  position: absolute;
  width: 16px !important;
  min-width: 40px;
  max-width: 40px;
  height: 16px;
  top: 20px;
  right: 10px;
  border: none;
  background-color: transparent;
  background-image: url("../../../../../assets/Frame\ 872.png");
  background-position: center;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}


@media (max-width: 700px) {
  .inputContainer {
    gap: 2px;
  }
}