@font-face {
    font-family: "Montserrat";
    src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
    .bodyBlock {
        width: auto !important;
    }

    .helpTitle {
        padding: 12px 40px;
    }
}

@media (min-width: 701px) {
    .helpTitle {
        padding: 40px 120px;
    }
}

.help {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.helpTitle {
    color: var(--black, #282828);
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}