.item {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
  height: 85px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.item:hover {
  border-top: 1px solid #3cadff;
  border-bottom: 1px solid #3cadff;
  background-color: #cbc9c9;
  transition: 200ms;
  padding-top: 20px;
  padding-bottom: 20px;
}

.item div {
  margin: auto;
}

.item div:nth-child(1) {
  display: flex;
  width: 160px;
  justify-content: center;
  flex-shrink: 0;
  font-size: 16px;
  align-items: center;
  gap: 8px;
  font-family: "Montserrat";
}

.item div:nth-child(2) {
  display: flex;
  width: 200px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  color: var(--black, #282828);
  text-align: center;

  /* text */
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.item div:nth-child(3) {
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-family: "Montserrat";
}

.item div:nth-child(4) {
  display: flex;
  width: 140px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  text-align: center;
  font-family: "Montserrat";
}

.item div:nth-child(5) {
  display: flex;
  width: 85px;
  text-align: right;
  flex-shrink: 0;
  gap: 8px;
  font-size: 16px;
  font-family: "Montserrat";
}

.item div:nth-child(6) {
  display: flex;
  width: 170px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-family: "Montserrat";
}

.item div:nth-child(7) {
  width: 100px;
}



.item button:hover {
  border: 1px solid var(--light-blue, #3cadff);
  color: white;
  transition: 200ms;
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);
  /* shadow-button */
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}