.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 10px;
  background-color: #3cadff;

  color: #fafafa;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
  border-radius: 12px;
  text-align: center;
}

.baseInfoForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 21px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
  width: 100%;
}

.formGroup span {
  color: var(--black, #282828);
  /* SubTitle */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formGroup input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: calc(100% - 40px);
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.textArea {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.textArea span {
  color: var(--black, #282828);
  /* SubTitle */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textArea textarea {
  padding: 12px 20px;
  width: calc(100% - 40px);
  border-radius: 12px;
  border: none;
  margin-top: 12px;
  font-family: "Montserrat";
  height: 305px !important;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
  resize: none;
}

.saleText {
  color: #acacac !important;
  font-size: 16px !important;
  font-family: "Montserrat" !important;
  display: flex;
  gap: 4px;
}

.saleText span {
  font-weight: 600;
  color: #acacac;
  font-size: 16px;
}

.emergancyText {
  max-width: 284px;
  width: 100%;
  color: var(--black, #282828);
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  margin-top: -15px;

  padding: 0px !important;
  font-weight: 400;
  line-height: normal;
}

.emergancy {
  color: red;
  font-weight: 600;
}

.fieldError {
  color: red !important;
  font-size: 13px !important;
}

.formGroup .inputError {
  border: 1px solid red;
  box-shadow: none;
  display: none !important;
}

@media (min-width: 701px) {
  .fieldsColumn {
    display: flex;
    flex-direction: column;

    min-width: 240px;
  }

  .fieldsColumn:first-child {
    width: calc((100% - 50px) / 4);
  }

  .fieldsColumn:nth-child(2) {
    width: calc((100% - 50px) / 2);
  }

  .fieldsColumn:last-child {
    width: calc((100% - 50px) / 4);
  }
}

@media (max-width: 700px) {
  .fieldsColumn {
    justify-content: center;
    width: 100%;
  }

  .emergancyText {
    max-width: 100%;
  }

  .header {
    margin: 0px 0px 30px;
  }
}

.descriptionLabels {
  display: flex;
  gap: 6px;
}

.categoriesSettings {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #0e86f8;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.categoriesBlock {
  display: flex;
  gap: 4px;
}

.generateBtn {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #0e86f8;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.generateBtn:hover,
.categoriesSettings:hover {
  background: linear-gradient(300.48deg, #3cadff 45.48%, #ffffff 132.16%);
  box-shadow: 0px 0px 0px 0px #1111222e, 0px 3px 6px 0px #1111222e;
  color: white;
}

.loader {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  position: relative;
  margin-left: 50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}