@media (max-width: 700px) {

    .container {
        overflow: hidden;
    }

    .bodyBlock {
        width: auto !important;
    }

    .marketingBlocksContainerTitle {
        box-sizing: border-box;
    }

    .marketingBlocks {
        flex-direction: column;
        align-items: center;
    }

    .chooseImg {
        max-width: 380px;
    }

    .choosenImg {
        width: 360px;
    }

    .emptyInput {
        width: 360px;
    }

    .marketingBlockText {
        width: 360px;
    }

    .sendBtn {
        width: 284px;
    }

}

@media (min-width: 701px) {
    /* .container {
        padding: 40px 120px;
    } */

    .chooseImg {
        max-width: 280px;
    }

    .emptyInput {
        width: 280px;
    }

    .marketingBlockText {
        width: 800px;
    }
}

.megaContainer {
    width: auto;
    display: flex;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 60px;
    box-sizing: border-box;
    align-items: center;
}

.title {
    color: var(--black, #282828);
    /* H4 */
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    align-self: flex-start;
    line-height: normal;
    text-transform: uppercase;
    padding-top: 40px;
}

.marketingBlocksContainer {
    display: flex;
    gap: 60px;
    flex-direction: column;
}

.marketingBlocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.marketingBlocksContainerTitle {
    text-align: center;
    width: 100%;
    color: white;
    padding: 10px;
    border-radius: 12px;
    background: var(--light-blue, #3CADFF);
    color: var(--white, #FAFAFA);
    align-self: center;
    /* H3-tablet */
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;

}

.chooseImg {
    border-radius: 20px;
    overflow: hidden;
    height: 356.502px;
    flex-shrink: 0;
}

.choosenImg {
    height: 356.502px;
    border-radius: 20px;
}

.emptyInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 356.502px;
    border-radius: 20px;
    justify-content: center;
    font-family: "Montserrat";
    color: gray;
    background-color: white;
    border: 1px solid black;
}

.emptyInput img {
    width: 40px;
    height: 40px;
}

.marketingBlockText {
    display: flex;
    /* height: 353px; */
    min-height: 300px;
    padding: 24px;
    align-items: flex-start;
    box-sizing: border-box;
    font-family: "Montserrat";
    gap: 10px;
    border-radius: 20px;
    border: 1px solid var(--gray, #ACACAC);
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.sendBtn {
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    display: flex;
    padding: 14px 32px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-family: "Montserrat";
    font-size: 16px;
    color: white;
}

.sendBtn:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.historyContainer {
    width: 100%;
}

.historyLoader {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: relative;
    margin-bottom: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.marketingBlockContainer {
    position: relative;
}

.quickButtons {
    max-width: 400px;
    position: absolute;
    right: 8px;
    top: 320px;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

.marketingBlockText {
    display: flex;
    height: 353px;
    min-height: 300px;
    padding: 24px;
    align-items: flex-start;
    box-sizing: border-box;
    font-family: "Montserrat";
    gap: 10px;
    border-radius: 20px;
    border: 1px solid var(--gray, #ACACAC);
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.quickButtons button {
    background-color: #3CADFF;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.quickButtons button:hover {
    background-color: #367fa9;
}