.pin-code-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.top-content {
    text-align: center;
    margin-bottom: 40px;
    max-width: 600px;
}

.logo {
    width: 200px;
}

.pin-code-input {
    padding: 15px;
    font-size: 24px;
    text-align: center;
    border: 2px solid #007bff;
    border-radius: 8px;
    min-width: 300px;
    margin-bottom: 20px;
    transition: border-color 0.3s ease;
}

.pin-code-input::placeholder {
    opacity: 0.7;
}

.pin-code-button {
    padding: 12px 24px;
    font-size: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button-container {
    display: flex;
    justify-content: center;
}

.pin-code-input::after {
    content: ' ';
    display: inline-block;
    width: 10px;
    text-align: center;
    opacity: 0.7;
}

.pin-code-input {
    letter-spacing: 10px;
}

.pin-code-input::placeholder {
    letter-spacing: normal !important;
}

.pin-code-button:hover {
    background-color: #0056b3;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}