@font-face {
  font-family: "Montserrat";
  src: url("../assets/Montserrat-VariableFont_wght.ttf");
}

.dropdown {
  display: inline-block;
  position: relative;
  width: 100% !important;
}

.dropdownToggle {
  padding: 12px 20px !important;
  border: none;
  font-family: "Montserrat";
  width: 100% !important;
  /* max-width: 324px; */
  cursor: pointer;
  height: auto !important;
  border-radius: 12px;
  background: var(--white, #fafafa) !important;
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
  text-align: left;
  color: gray;
}

.dropdownMenu {
  display: block;
  list-style: none;
  margin: 0;
  width: 100%;
  position: absolute;
  z-index: 5;
  max-height: 300px;
  overflow-y: scroll;
  border: 1px solid #3cadff;
  top: 0;
  left: 0;
  border-radius: 18px;
  padding: 0;
}

.dropdownMenu::-webkit-scrollbar {
  width: 12px;
  border-radius: 16px;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  background-color: #a5a1a1;
  border-radius: 16px;
}

.dropdownMenu::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 16px;
}

.dropdownMenu::-webkit-scrollbar {
  width: 12px;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  border-radius: 16px 16px 16px 16px;
  background: var(--gray, #acacac);
}

/* для firefox  */
.dropdownMenu::-moz-scrollbar {
  width: 12px;
  border-radius: 16px;
}

.dropdownMenu::-moz-scrollbar-thumb {
  background-color: #a5a1a1;
  border-radius: 16px;
}

.dropdownMenu::-moz-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 16px;
}

/* для internet Explorer */
.dropdownMenu::-ms-scrollbar {
  width: 12px;
  border-radius: 16px;
}

.dropdownMenu::-ms-scrollbar-thumb {
  background-color: #a5a1a1;
  border-radius: 16px;
}

.dropdownMenu::-ms-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 16px;
}

.dropdownMenu li {
  background: #fff;
  padding: 14px 24px;
  color: gray;
  font-size: 14px;
  font-family: "Montserrat";
  cursor: pointer;
}

.dropdownMenu li:hover {
  background: #3cadff;
  transition: 200ms;
  color: white;
}