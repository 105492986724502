@font-face {
    font-family: "Montserrat";
    src: url("../assets/Montserrat-VariableFont_wght.ttf");
  }

  @media (max-width: 700px) {
    .dropdown {
      display: none;
    }
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdownToggle {
    padding: 12px 24px;
    display: flex;
    cursor: pointer;
    gap: 8px;
    border: 1px solid #3CADFF;
    background-color: transparent;
    margin-left: 50px;
    font-size: 20px;
    font-family: "Montserrat";
    border-radius: 18px;
    color: #3CADFF;
  }

  .dropdownToggle:hover {
    color: white;
    background-color: #3CADFF;
  }
  
  .dropdownMenu {
    display: block;
    list-style: none;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 25px;
    border: 1px solid #3CADFF;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(14px);
    border-radius: 20px;
    overflow: hidden;
    z-index: 5;
  }
  
  .dropdownMenu li {
    background: #fff;
    padding: 10px 20px;
    margin-bottom: 0px !important;
    border-bottom: 1px solid #3CADFF;
    font-size: 20px;
    font-family: "Montserrat";
    cursor: pointer;
  }
  
  .dropdownMenu li:last-child {
    border-bottom: none;
  }
  
  .dropdownMenu li:hover {
    background: linear-gradient(0deg, rgba(60, 173, 255, 0.20) 0%, rgba(60, 173, 255, 0.20) 100%), #FAFAFA;
  }
  
  