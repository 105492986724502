.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.buttonSecondary {
  margin: 0 !important;
  color: #3cadff;
  border: 1px solid #3cadff;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border-radius: 18px;
  padding: 5px 10px;
  background: none;
  font-size: 14px;
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
}

.buttonSecondary:hover {
  background: linear-gradient(321.48deg, #3cadff 45.48%, #ffffff 132.16%);
  box-shadow: 0px 0px 0px 0px #1111222e, 0px 3px 6px 0px #1111222e,
    0px 11px 11px 0px #11112226, 0px 24px 14px 0px #11112217,
    0px 43px 17px 0px #11112208, 0px 67px 19px 0px #11112200;
  color: white;
  cursor: pointer !important;
}

.buttonPrimary {
  margin: 0 !important;
  color: #fafafa;
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  font-weight: 600;
  border-radius: 18px;
  border: none;
  padding: 5px 10px;
  background: #0e86f8;
  font-size: 14px;
  position: relative;
  margin-right: 20px;
  margin-left: 20px;
}

.buttonPrimary:hover {
  background: linear-gradient(321.48deg, #3cadff 45.48%, #ffffff 132.16%);
  box-shadow: 0px 0px 0px 0px #1111222e, 0px 3px 6px 0px #1111222e,
    0px 11px 11px 0px #11112226, 0px 24px 14px 0px #11112217,
    0px 43px 17px 0px #11112208, 0px 67px 19px 0px #11112200;
  color: white;
  cursor: pointer !important;
}

.buttonSecondary:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonPrimary:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttonSecondary:nth-child(n + 2):not(:last-child) {
  border-radius: 0;
  border-radius: 0;
}

.buttonPrimary:nth-child(n + 2):not(:last-child) {
  border-radius: 0;
  border-radius: 0;
}

.buttonSecondary:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttonPrimary:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (max-width: 700px) {

  .wrapper {
    justify-content: center;
  }

  .buttonSecondary,
  .buttonPrimary {
    padding: 10px calc(30% / 3);
  }

}