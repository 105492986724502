.item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 1200px;
    font-family: "Montserrat";
}

.img {
    width: 94px !important;
    border-radius: 8px;
    margin-left: 15px;
    margin-right: 20px;
}

.info {
    display: flex;
    align-items: flex-start;
    width: 250px;
    flex-direction: column;
}


.info span {
    color: var(--blue, #0E86F8);
    /* ST2 */
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.status {
    width: 140px;
    text-align: right;
}

.option {
    display: flex;
    align-items: center;
    gap: 5px;
}

.option span {
    color: black;
    font-weight: 400;
    font-size: 16px;
}

.count {
    width: 170px;
    text-align: right;
}

.price {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 122px;
    margin-left: 60px;
}

/* .price span:nth-child(1) {
    color: gray;
    text-decoration: line-through;
    margin-bottom: 5px;
} */

/* .price span:nth-child(1)::after {
    content: "\20BD";
    margin-left: 5px;
} */

.price span:nth-child(2) {
    color: green;
}

/* .price span:nth-child(2)::after {
    content: "\20BD";
    margin-left: 5px;
} */

.sale {
    width: 104px;
    margin: auto;
    text-align: center;
}

/* .sale::after {
    content: "\20BD";
    margin-left: 5px;
} */

.sum {
    width: 142px;
    text-align: center;
    margin: auto;
    font-weight: 600;
}

/* .sum::after {
    content: "\20BD";
    margin-left: 5px;
} */