@font-face {
    font-family: "Montserrat";
    src: url("../../../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
    .item {
        flex-direction: column;
        padding: 22px;
        min-width: 330px;
        max-width: 590px;
        width: 390px;
    }

    .btns {
        flex-direction: column;
    }
}

@media (min-width: 701px) {
    .item {
        padding: 30px 24px;
        min-width: 530px;
        max-width: 590px;
    }

    .imgOrText {
        width: 35%;
    }

    .imgOrText img {
        width: 100%;
    }
}

.item {
    display: flex;
    gap: 20px;
    align-items: center;
    box-sizing: border-box;
    width: 48%;
    border-radius: 28px;
    background: var(--white, #FAFAFA);
    box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
}

.imgOrText {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    flex-shrink: 0;
}

.imgOrText img {
    height: 147px;
    border-radius: 24px;
}

.textAndImg {
    height: 147px;
    border-radius: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.textAndImg img {
    width: 72px;
    border-radius: 0px;
    height: 50px;
}

.textAndImg span {
    color: var(--clothes-brown, black);
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;

    /* ST2 */
    font-family: "Montserrat";
    font-size: 18px;
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.info span {
    color: #000;
    /* text */
    font-family: "Montserrat";
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btns {
    display: flex;
    width: 100%;
    /* flex-wrap: wrap; */
    gap: 13px;
}

.look {
    display: flex;
    padding: 14px 32px;
    cursor: pointer;
    justify-content: center;
    color: white;
    font-family: "Montserrat";
    font-size: 16px;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    background: var(--blue, #0E86F8);
    border: none;
}

.look:hover {
    transition: 200ms;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.delete {
    display: flex;
    font-size: 16px;
    padding: 14px 32px;
    justify-content: center;
    cursor: pointer;
    color: #3CADFF;
    font-family: "Montserrat";
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);
}

.delete:hover {
    color: white;
    transition: 200ms;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.popupBtnBack {
    border-radius: 18px;
    border: 1px solid var(--light-blue, #3CADFF);
    background: var(--white, #FAFAFA);
    font-family: 'Montserrat';
    cursor: pointer;
    font-size: 16px;
    padding: 14px 32px;
    color: #3CADFF;
}


.popupBtnBack:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.popupBtnDel {
    border-radius: 18px;
    background: var(--blue, #0E86F8);
    border: none;
    padding: 14px 32px;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 16px;
    color: white;
}

.popupBtnDel:hover {
    transition: 200ms;
    color: white;
    background: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}


.popupClose {
    position: absolute;
    width: 16px !important;
    min-width: 40px;
    max-width: 40px;
    height: 16px;
    top: 20px;
    right: 10px;
    border: none;
    background-color: transparent;
    background-image: url("../../../assets/Frame\ 872.png");
    background-position: center;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    cursor: pointer;
}