.container {
    display: flex;
    width: 1200px;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
    gap: 2px;
    box-sizing: border-box;
}

.text {
    font-family: "Montserrat";
    font-size: 18px;
}

.href {
    color: #3CADFF;
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
    font-family: "Montserrat";
}
