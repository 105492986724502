.banners-page {
    padding: 20px;
    max-width: 100%;
}

.banners-page h1 {
    text-align: center;
}

.page-title {
    margin-bottom: 20px;
}

.banner-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
}

.create-banner-card-wrapper {
    display: flex;
    justify-content: center;

}

.create-banner-card {
    margin: 10px;
    padding: 10px;
    width: 80%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ccc;
    border-radius: 8px;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
}

.create-banner-card:hover {
    background-color: #f0f0f0;
}

.add-button {
    font-size: 20px;
    line-height: 1;
    color: #aaa;
    text-decoration: none;
    /* Убираем подчеркивание */
    transition: color 0.3s;
    padding: 10px;
    /* Добавляем внутренний отступ */
}

.create-banner-card:hover .add-button {
    color: #333;
}