@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.container {
  position: fixed;
  top: 80px;
  right: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 360px;

  z-index: 10000;

  border-radius: 12px;

  box-shadow: 0 0 8px 2px rgba(55, 55, 116, 0.15);

  font-family: "Montserrat";
  font-weight: 400;

  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slideIn {
  animation-name: slideIn;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

.error {
  background-color: rgba(232, 142, 142, 0.3);
  backdrop-filter: blur(10px);
}

.errorWarningText {
  color: red;
  font-weight: 600;
}

.success {
  background-color: rgba(0, 125, 20, 0.3);
  backdrop-filter: blur(10px);
}

.content {
  padding: 20px 5px 20px 20px;
}

.closeButton {
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;

  background-repeat: no-repeat;
}
