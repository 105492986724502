.infoImg {
    cursor: pointer;
    width: 18px;
    height: 18px;
}

@media (min-width: 701px) {
    .container {
        padding: 40px 15%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat";
    align-items: flex-start;
}

.InnerContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

.containerTitle {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: flex-start;
}

.containerTitle span:nth-child(1) {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Montserrat";
}

.catgoriesSubcategroiesList {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
}

.categoryContainer {
    display: flex;
    flex-direction: column;
}

.category {
    display: flex;
    align-items: center;
}

.subcategoriesContainer {
    display: flex;
    margin-left: 30px;
    gap: 12px;
    flex-direction: column;
    margin: 20px 30px;
}

.subcategory {
    display: flex;
    align-items: center;
}


.toggleButton {
    width: 20px;
    cursor: pointer;
    margin-left: 5px;
    height: 20px;
}

.addCategory {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    width: 100%;
}

.addCategoryContainer span {
    font-weight: 600;
}

.newCategoryInput {
    display: flex;
    gap: 15px;
    width: 100%;
}

.addCategoryContainer {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex-direction: column;
}

.newCategoryInput input {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    font-size: 16px;
    color: black;
    border-radius: 12px;
    border: 1px solid var(--gray, #ACACAC);
    background: var(--white, #FAFAFA);
}

.addCategoryBtn {
    border-radius: 12px;
    border: 1px solid var(--light-blue, #3CADFF);
    background-color: var(--white, #FAFAFA);
    font-family: 'Montserrat';
    cursor: pointer;
    height: 36px;
    width: 50px;
    background-image: url("../assets/free-icon-plus-17556213.png");
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: center;
    padding: 20px;
    color: #3CADFF;
}

.addCategoryBtn:hover {
    transition: 200ms;
    background-color: linear-gradient(321deg, #3CADFF 45.48%, #FFF 132.16%);
    box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18), 0px 3px 6px 0px rgba(17, 17, 34, 0.18), 0px 11px 11px 0px rgba(17, 17, 34, 0.15), 0px 24px 14px 0px rgba(17, 17, 34, 0.09), 0px 43px 17px 0px rgba(17, 17, 34, 0.03), 0px 67px 19px 0px rgba(17, 17, 34, 0.00);
}

.addSubCategory {
    display: flex;
    gap: 15px;
    align-items: flex-end;
}

.addSubCategoryInput {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    font-size: 16px;
    color: #ACACAC;
    border-radius: 12px;
    border: 1px solid var(--gray, #ACACAC);
    background: var(--white, #FAFAFA);
}

.addSubCategoryDropdown {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    font-size: 16px;
    color: black;
    border-radius: 12px;
    border: 1px solid var(--gray, #ACACAC);
    background: var(--white, #FAFAFA);
}

.infoImg {
    cursor: pointer;
    width: 18px;
    height: 18px;
}

.addCategoryInputCont {
    position: relative;
}

.notificationBlock {
    position: absolute;
    justify-content: center;
    align-items: center;
    right: -95%;
    top: -10%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px 10px;
    border-radius: 10px;
    background: var(--light-gray, #EEE);
    width: 200px !important;
    z-index: 3;
    overflow: hidden;
    color: var(--black, #282828);
    text-overflow: ellipsis;
    font-family: "Montserrat";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.notificationBlock p {
    margin: 0;
    text-transform: none;
}

@media (max-width: 700px) {

    .container {
        padding: 100px 5%;
    }

    .addCategory {
        flex-direction: column;
    }

    .addCategoryContainer input {
        width: 250px;
    }
}

@media (min-width: 701px) {
    .addCategoryContainer input {
        width: 286px;
    }
}