@media (max-width: 700px) {
    .bodyBlock {
        width: auto !important;
    }

    .container {
        padding: 12px 40px;
    }
}

@media (min-width: 701px) {
    .container {
        padding: 40px 120px;
    }

    .toolTip {
        width: 80%;
        min-width: 1000px;
    }
}


.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.title {
    color: var(--black, #282828);
    /* H4 */
    font-family: "Montserrat";
    align-self: flex-start;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.list {
    display: flex;
    flex-direction: column;
}


.toolTip {
    color: black;
    font-family: "Montserrat";
    font-size: 16px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    padding: 28px 32px;
    border-radius: 12px;
    border: 1px solid var(--gray, #ACACAC);
    gap: 12px;
    cursor: pointer;
}

.toolTip span {
    color: var(--black, #282828);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.toolTipVisible {
    color: rgb(173, 170, 170);
    font-family: "Montserrat";
    font-size: 16px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    padding: 28px 32px;
    border-radius: 12px;
    border: 1px solid var(--gray, #ACACAC);
    min-width: 90%;
}

.toolTipVisible span {
    color: var(--black, #ACACAC);

    /* SubTitle */
    font-family: "Montserrat";
    margin-bottom: 12px;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.datetime {
    margin: 0;
    text-align: right;
    color: black;
}

.seenTag {
    margin: 0;
    margin-top: 20px;
    text-align: right;
    color: black;
}