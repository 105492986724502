@media (max-width: 700px) {
  .container {
    padding: 24px 12px;
    width: 284px;
    height: 396px;
  }

  .list div {
    font-size: 14px;
  }

  .btns {
    font-size: 14px;
    gap: 4px;
  }

  .form input {
    width: 215px;
  }
}

@media (min-width: 701px) {
  .container {
    width: 284px;
    height: 490px;
    padding: 40px 56px;
  }

  .btns {
    gap: 8px;
  }

  .form input {
    width: 235px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.1);
}

.title {
  color: var(--black, #282828);

  /* ST2 */
  font-family: "Montserrat";
  margin-bottom: 24px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.list {
  width: 284px;
  height: 383px;
  padding: 5px;
  overflow-y: scroll;
  border-radius: 12px;
  margin-bottom: 40px;
  border: 1px solid var(--gray, #acacac);
}

.list::-webkit-scrollbar {
  width: 16px;
}

.list::-webkit-scrollbar-thumb {
  border-radius: 20px 20px 20px 20px;
  background: var(--gray, #acacac);
}

.list div {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  flex-wrap: nowrap;
  flex-shrink: 0;
}

.list span {
  padding: 12px;
}

.list input {
  width: 15px;
  height: 15px;
}

.btns {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.btns button {
  border-radius: 12px;
  border: 1px solid var(--light-blue, #3cadff);
  background: white;
  cursor: pointer;
  color: #3cadff;
  box-sizing: border-box;
  padding: 8px 14px;
  font-family: "Montserrat";
}

.form {
  display: flex;
  align-items: center;
}

.form input {
  border-radius: 12px;
  background: #fff;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
  border: none;
}

.form input:focus {
  border: none;
  outline: none;
}

.form button {
  width: 50px;
  height: 44px;
   background-size: 50px 44px;
  cursor: pointer;
  padding: 0px;
  border: none;
  background-color: transparent;
  background-image: url("../../../assets/icon\ addPlus.png");
  background-repeat: no-repeat;
 
  background-position: center;
  flex-shrink: 0;
}

.activeBtn {
  transition: 400ms;
  background-color: #3cadff !important;
  color: white !important;
}

.list input[type="checkbox"] {
  display: none;
}

.list label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: inline-block;
}

.list label::before {
  content: "";
  position: absolute;

  left: 0;
  top: 0;
  width: 20px;
  /* Ширина кастомной галочки */
  height: 20px;
  /* Высота кастомной галочки */
  border: 1px solid #333;
  border-radius: 6px;
  /* Цвет рамки */
}

.list label::after {
  content: "✔";
  font-size: 18px;
  color: #3cadff;
  position: absolute;
  left: 5px;
  top: -2px;
  opacity: 0;
  transition: opacity 0.2s;
}

/* Стилизация галочки при ее выборе */
input[type="checkbox"]:checked + label::after {
  opacity: 1;
}
