@media (min-width: 701px) {
    .openBurger {
        display: none;
    }

    .closeBurger {
        display: none;
    }
}


.openBurger {
    width: 50px;
    height: 50px;
    background-color: white;
    background-image: url("../assets/burger\ menu.png");
    background-position: center;
    background-size: cover;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.closeBurger {
    width: 50px;
    height: 50px;
    border: none;
    background-color: white;
    background-image: url("../assets/burger\ menu\ \(1\).png");
    background-position: center;
    background-size: cover;
    border-radius: 12px;
}

.menuContainer {
    position: fixed;
    display: flex;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    flex-direction: column;
    width: 100vw;
    background-color: white;
}

.menuBlock {
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-self: center;
    text-align: right;
    align-items: center;
}

.menuBlockTitle {
    background-color: #3CADFF;
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 8px 0px 8px 0px;
    width: 100%;
}


.menuList {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    gap: 10px;
    align-self: flex-end;
    justify-self: flex-end;
    padding-right: 24px;
    list-style: none;
}


.menuListItem {
    cursor: pointer;
}

.menuListItem li{
    text-decoration: none;
    cursor: pointer;
    padding: 8px 24px 8px 24px;
}

.menuListItem a {
    text-decoration: none;
    color: black;
    cursor: pointer;
    padding: 8px, 24px, 8px, 24px;
}
