.wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  gap: 21px;
  margin-top: 60px;
}

.fieldsColumn {
  display: flex;
  flex-direction: column;

  min-width: 240px;
}

.fieldsColumn:first-child {
  width: calc((100% - 50px) / 4);
}

.fieldsColumn:nth-child(2) {
  width: calc((100% - 50px) / 2);
}

.fieldsColumn:last-child {
  width: calc((100% - 50px) / 4);
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  margin-bottom: 20px;

  width: 100%;
}

.formGroup span {
  color: var(--black, #282828);

  /* SubTitle */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formGroup input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: calc(100% - 40px);
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.textArea {
  display: flex;
  flex-direction: column;
  resize: none;
}

.textArea span {
  color: var(--black, #282828);
  /* SubTitle */
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textArea textarea {
  padding: 12px 20px;
  width: calc(100% - 40px);
  border-radius: 12px;
  border: none;
  margin-top: 12px;
  font-family: "Montserrat";
  height: 220px !important;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
  resize: none;
}

.photosTitle {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 20px;
}

.saleText {
  margin-top: 12px;
  color: #acacac !important;
  font-size: 16px !important;
  font-family: "Montserrat" !important;
}

.saleText span {
  font-weight: 600;
  color: #acacac;
  font-size: 16px;
}

.emergancyText {
  width: 284px;

  color: var(--black, #282828);
  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  margin-top: -15px;

  padding: 0px !important;
  font-weight: 400;
  line-height: normal;
}

.emergancy {
  color: red;
  font-weight: 600;
}

.requiredCharsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.formGroupShort {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
}

.formGroupShort span {
  color: var(--black, #282828);
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formGroupShort input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: 158px;
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.fieldError {
  color: red !important;
  font-size: 13px !important;
}


.formVideoInputCont {
  display: flex;
  margin-top: 20px;
  gap: 12px;
  flex-direction: column;
  align-items: flex-start;
}




.formVideoInputNameCont {
  display: flex;
  align-items: center;
  position: relative;
  gap: 3px;
}

.infoImg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.notificationBlock {
  position: absolute;
  justify-content: center;
  align-items: center;
  right: -180%;
  top: -50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px 10px;
  border-radius: 10px;
  background: var(--light-gray, #EEE);
  width: 200px !important;
  z-index: 3;
  overflow: hidden;
  color: var(--black, #282828);
  text-overflow: ellipsis;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formVideoInputCont input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  width: calc(100% - 40px);
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

@media (max-width: 700px) {
  .fieldsColumn:first-child {
    width: 100%
  }

  .fieldsColumn:nth-child(2) {
    width: 100%;
  }

  .fieldsColumn:last-child {
    width: 100%;
  }

  .requiredCharsWrapper {
    width: 100%;
  }

  .formGroupShort {
    width: 100%;
  }

  .formGroupShort input {
    width: calc(100% - 40px);
  }
}