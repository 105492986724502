@font-face {
  font-family: "Montserrat";
  src: url("../assets/Montserrat-VariableFont_wght.ttf");
}

@media (max-width: 700px) {
  .header {
    width: 100vw;
    z-index: 10;
    position: fixed;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .headerRowInfo span:nth-child(1) {
    display: none;
  }

  .headerRow:nth-child(1) {
    padding: 12px 40px 12px 40px;
    align-items: center;
  }

  .headerRow:nth-child(2) {
    display: none;
  }

  .headerRowLogo {
    width: 111px;
    height: 23px;
  }

  .dropContainer {
    display: none;
  }

  h1 {
    margin-top: 100px;
  }
}

@media (min-width: 701px) {
  .header {
    width: 100%;
  }

  .headerRow:nth-child(1) {
    padding: 20px 120px;
    flex-wrap: wrap;
  }

  .headerRowLogo {
    width: 212px;
    height: 43px;
  }
}

body {
  margin: 0px;
}

.header {
  display: flex;
  flex-direction: column;
}

.headerRow:nth-child(1) {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
}

.headerRowLogo {
  flex-shrink: 0;
}

.headerRowInfo {
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.headerRowInfo span {
  font-size: 20px;
  font-family: "Montserrat";
  margin-right: 20px;
}

.headerProfile {
  padding: 12px 24px;
  border: 1px solid #3cadff;
  background-color: transparent;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  border-radius: 18px;
  color: #3cadff;
  font-family: "Montserrat";
  gap: 10px;
}

.headerProfile:hover {
  color: black;
  background-color: #3cadff;
  transition: 300ms !important;
}

.headerRowInfo button:nth-child(2) img {
  width: 27px;
  height: 27px;
}

.headerRow:nth-child(2) {
  background: #3cadff;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 120px;
}

.headerRow:nth-child(2) ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.headerRow:nth-child(2) ul li {
  margin: auto;
}

.headerRow:nth-child(2) ul li:nth-child(1) {
  margin-left: 0px;
}

.headerRow:nth-child(2) ul li a {
  color: white;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
}

.headerRow:nth-child(2) ul li a:hover {
  font-weight: 600;
  transition: 200ms;
}

.goOut {
  display: flex;
  width: 184px;
  padding: 14px 10px;
  justify-content: center;
  align-items: center;
  color: #3cadff;
  margin-left: 20px;
  gap: 10px;
  border-radius: 18px;
  border: 1px solid var(--light-blue, #3cadff);
  background: var(--white, #fafafa);
}

.goOut:hover {
  color: white;
  transition: 200ms;
  border: 1px solid var(--light-blue, #3cadff);
  background: linear-gradient(321deg, #3cadff 45.48%, #fff 132.16%);

  /* shadow-button */
  box-shadow: 0px 0px 0px 0px rgba(17, 17, 34, 0.18),
    0px 3px 6px 0px rgba(17, 17, 34, 0.18),
    0px 11px 11px 0px rgba(17, 17, 34, 0.15),
    0px 24px 14px 0px rgba(17, 17, 34, 0.09),
    0px 43px 17px 0px rgba(17, 17, 34, 0.03),
    0px 67px 19px 0px rgba(17, 17, 34, 0);
}