.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
}

.formGroupShort {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 20px;
}

.formGroupShort span {
  color: var(--black, #282828);
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.formGroupShort input {
  padding: 12px 20px;
  border: none;
  font-family: "Montserrat";
  min-width: 158px;
  border-radius: 12px;
  background: var(--white, #fafafa);
  box-shadow: 0px 2px 8px 2px rgba(55, 55, 116, 0.15);
  backdrop-filter: blur(2px);
}

.fieldError {
  color: red !important;
  font-size: 13px !important;
}

@media (max-width: 700px) {
  .wrapper {
    width: 100%;
  }

  .formGroupShort {
    width: 100%;
  }

  .formGroupShort input {
    width: calc(100% - 40px);
  }
}